import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, InjectionToken, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { CallbackComponent } from './authentication/callback/callback.component';
import { HttpErrorInterceptor } from './common/http-error.interceptor';
import { getErrorHandler } from './common/SentryErrorHandler';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './authentication/token.interceptor';
import { JsonInterceptor } from './authentication/json.interceptor';
import { LogoutComponent } from './authentication/logout/logout.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { RedirectComponent } from './redirect/redirect.component';
import { CommonModule as GstyleCommon } from './common/common.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { DriverModule } from './driver/driver.module';
import * as Sentry from '@sentry/browser';

@NgModule({
    declarations: [
        AppComponent,
        CallbackComponent,
        LogoutComponent,
        RedirectComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        LoadingBarHttpClientModule,
        GstyleCommon,
        AuthenticationModule,
        DriverModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            primaryColour: '#ffffff',
            secondaryColour: '#ccc'
        }),
        ServiceWorkerModule.register('service-worker.js', { enabled: environment.production }),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: getErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
