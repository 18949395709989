import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {

    public visibilityChange = new BehaviorSubject(true);

    constructor() {
        // Handle page visibility change
        document.addEventListener('visibilitychange', () => {
            if (document['hidden']) {
                this.visibilityChange.next(false);
            } else {
                this.visibilityChange.next(true);
            }
        }, false);
    }

}
