<div class="load" fxLayout="row wrap">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="load-indicator">
        <h2>
            Load {{ load.number }}
        </h2>
    </div>

    <div class="load-form" [formGroup]="form" fxFlex.md="50" fxFlex.lt-md="100">
        <div formGroupName="load" fxLayout="column" class="fillable-form">

            <!-- Status -->
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" name="status">
                    <mat-option value="created">Not Started</mat-option>
                    <mat-option value="started">Started</mat-option>
                    <mat-option value="finished">Finished</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Locations -->
            <div fxLayout="row wrap">
                <!-- Pickup -->
                <div fxFlex.lg="50" fxFlex.lt-lg="100" fxLayout="row wrap">
                    <div fxFlex="100" fxLayout="row wrap">
                        <p fxFlexAlign="start start">
                            <b>Pickup Name: </b> {{ loadRoute?.pickup_name }}
                        </p>
                        <div fxFlex fxFlexAlign="start center" class="btn-load-location">
                            <a target="_blank" rel="noopener noreferrer" [href]="toPickup" mat-button>
                                <mat-icon>map</mat-icon>
                            </a>
                        </div>
                    </div>
                    <mat-form-field fxFlex="100">
                        <input [readonly]="true" matInput placeholder="Pickup Coordinates"
                            [value]="loadRoute?.pickup_location" />
                    </mat-form-field>
                </div>
                <!-- Dropoff -->
                <div fxFlex.lg="50" fxFlex.lt-lg="100" fxLayout="row wrap">
                    <div fxFlex="100" fxLayout="row wrap">
                        <p fxFlexAlign="start start">
                            <b>Dropoff Name: </b> {{ loadRoute?.dropoff_name }}
                        </p>
                        <div fxFlex fxFlexAlign="start center" class="btn-load-location">
                            <a target="_blank" rel="noopener noreferrer" [href]="toDropoff" mat-button>
                                <mat-icon>map</mat-icon>
                            </a>
                        </div>

                    </div>
                    <mat-form-field fxFlex="100">
                        <input [readonly]="true" matInput placeholder="Dropoff Coordinates"
                            [value]="loadRoute?.dropoff_location" />
                    </mat-form-field>
                </div>
                <!-- Change Locations -->
                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" class="btn-change-route"
                    *ngIf="load.status !== 'finished' && !this.archived">
                    <button mat-stroked-button (click)="changeRoute()" [disabled]="!online">Change Locations</button>
                </div>
            </div>

            <!-- Billable Quantity -->
            <mat-form-field>
                <input matInput name="load_billable_quantity" type="number" [readonly]="isBolComplete" [placeholder]="'Quantity of ' + billingTypeName + '(s)'"
                    formControlName="billable_quantity">
                <mat-error *ngIf="form.get('load.billable_quantity').hasError('invalidNum')">
                    Quantity of {{ billingTypeName }}(s) must be a number.
                </mat-error>
                <mat-error *ngIf="form.get('load.billable_quantity').hasError('outOfRange')">
                    Quantity of {{ billingTypeName }}(s) is too big.
                </mat-error>
            </mat-form-field>

            <!-- Billable Quantity Optional -->
            <mat-form-field *ngIf="billingTypeOptionalName">
                <input matInput name="load_billable_quantity_optional" type="number" [readonly]="isBolComplete" [placeholder]="'Quantity of ' + billingTypeOptionalName + '(s)'"
                    formControlName="billable_quantity_optional">
                <mat-error *ngIf="form.get('load.billable_quantity_optional').hasError('invalidNum')">
                    Quantity of {{ billingTypeOptionalName }}(s) must be a number.
                </mat-error>
                <mat-error *ngIf="form.get('load.billable_quantity_optional').hasError('outOfRange')">
                    Quantity of {{ billingTypeOptionalName }}(s) is too big.
                </mat-error>
            </mat-form-field>

            <!-- Estimated Loads Remaining -->
            <mat-form-field>
                <input name="load_loads_remaining" type="number" [readonly]="isBolComplete" matInput placeholder="Estimated Loads Remaining"
                    formControlName="estimated_loads_remaining">
                <mat-error *ngIf="form.get('load.estimated_loads_remaining').hasError('invalidInt')">
                    Estimated loads must be an integer.
                </mat-error>
                <mat-error *ngIf="form.get('load.estimated_loads_remaining').hasError('outOfRange')">
                    Estimated loads is too big.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    
    <app-load-images class="load-images" [isBolComplete]="isBolComplete" [images]="loadImages" [status]="'started'" [sync]="sync" [loadId]="load.id" (loadImageChange)="imageChange($event)"></app-load-images>
    
    <div class="unsaved-changes" *ngIf="hasChanged || hasChangedImage">
        <div class="unsaved-text">
            Load has unsaved changes... <br />
            <div class="offline-warning" *ngIf="!online">
                <mat-icon>warning</mat-icon> <p>You are currently offline, the load will be saved when reconnected. </p>
            </div>
        </div>
        <ng-container *ngIf="saveLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </ng-container>
    </div>
</div>

