<div>
  <button class="back-button" mat-button (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div class="page">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="main-page">
  
      <div class="bol-header">
        <div class="title-header">
          <img *ngIf="brand?.logo" #logo [alt]="brand?.name + ' logo'" class="company-logo" [src]="brand?.logo">
          <h1 class="bol-title">Bill of Lading</h1>
          <h1 class="bol-id">{{bol?.id}}</h1>
        </div>
  
        <div class="first-row-header" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- <h1 class="gstyle-title">G-Style Transport</h1> -->
          <h1 *ngIf="brand?.name" class="gstyle-title">{{ brand.name }}</h1>
          <p *ngIf="brand?.phone_number">Dispatch: {{ brand.phone_number }}</p>
  
          <div class="po-box">
            <!-- <p>PO Box 2104</p>
            <p>Williston, ND 58802</p> -->
            <p *ngIf="brand?.address">{{ brand.address }}</p>
          </div>
        </div>

        <div *ngIf="job?.description">
          <p class="dispatchers-title">Job Description:</p>
          <p class="job-description">{{ job.description }}</p>
        </div>

  
        <div class="body-item bol-date">
          <p class="body-title">DATE: </p>
          <p>{{bol?.created_at | date : "MMM d, y" }}</p>
        </div>
  
        <div class="body-item point-of-origin">
          <p class="body-title">POINT OF ORIGIN: </p>
          <p>{{bol?.route.pickup_name}}</p>
        </div>
  
        <div class="fine-print">
          <p>
            the property described below, in apparent good order, except as noted (contents and condition of contents of packages-unkonwn), marked, consigned, and destined as indicated below, which said carrier (the word carrier being understood throughout this contract as meaning any person or corporation in procession of the propoerty under the contract) agrees to carry to its usual place of delivery at said destination, if on its route, otherwise to deliver to another carrier on the route to said destination. It is mutually agreed, as to each carrier of all or any of said property over all or any portion of said route to destination, and as to each party at any time interested in all or any of said property, that every service to be performed hereunder shall be subject to all the terms and conditions of the Uniform Domestic Straight Bill of Lading set forth (1) in Official, Southern, Western, and Illinois Freight Classifications in effect on the date hereof, if this is a rail or a rail-water shipment, or (2) in the applicable motor carrier classification or tariff if thus is a motor carrier shipment.
          </p>
          <p>
            <strong>
            <span class="indent">Shipper</span> hereby certifies that the is familiar with all the terms and conditions of the said bill of lading, including those on the back thereof, set forth in the classification or tariff which governs the transportation of this shipment, and the said terms and conditions are hereby agreed to by the shipper and accepted for himself and his assigns.
            </strong>
          </p>
        </div>
  
      </div>
  
      <div class="bol-body">
  
        <div class="main-items">
          <table class="main-items-left">
              <tr>
                <td class="title">Customer: </td>
                <td class="info">{{ customer?.name }}</td>
              </tr>
              <tr>
                <td class="title">Billed Customer: </td>
                <td class="info">{{ billedCustomer?.name }}</td>
              </tr>
              <tr *ngIf="bol && bol.route.dropoff_type == 'well'">
                <td class="title">Well Name: </td>
                <td class="info">{{well?.name}}</td>
              </tr>
              <tr *ngIf="bol && bol.route.dropoff_type == 'well' && rig">
                <td class="title">Rig: </td>
                <td class="info">{{rig?.name}}</td>
              </tr>
              <tr *ngIf="bol && bol.route.dropoff_type !== 'well'">
                <td class="title">Destination: </td>
                <td class="info">{{bol?.route.dropoff_name}}</td>
              </tr>
              <tr>
                <td class="title">Contact: </td>
                <td class="info">{{job?.contact}}</td>
              </tr>
          </table>
      
          <table class="main-items-right">
              <tr>
                <td class="title">Driver: </td>
                <td class="info">{{driver?.first_name}} {{driver?.last_name}}</td>
              </tr>
              <tr>
                <td class="title">Vehicle #: </td>
                <td class="info">{{vehicle?.truck_number}}</td>
              </tr>
          </table>
        </div>
  
        <div class="load-table">
          <table>
            <caption>
              <h2>Loads - {{billType?.name}}</h2>
            </caption>
            <tr>
              <th>
                Load Number
              </th>
              <th>
                Quantity of {{ billType?.name }}(s)
              </th>
              <th *ngIf="billTypeOptional">
                Quantity of {{ billTypeOptional?.name }}(s)
              </th>
            </tr>
            <tr *ngFor="let load of bol?.loads">
              <th>
                {{load?.number}}
              </th>
              <th>
                {{load?.billable_quantity}}
              </th>
              <th *ngIf="billTypeOptional">
                {{ load?.billable_quantity_optional }}
              </th>
            </tr>
          </table>
        </div>
  
        <div class="price-info">
          <table>
            <tr>
              <td class="title">Total {{ billType?.name }}(s): </td>
              <td class="info">{{ totalQuantity }}</td>
              <ng-container *ngIf="billTypeOptional">
                <td class="title">Total {{ billTypeOptional?.name }}(s): </td>
                <td class="info">{{ totalQuantityOptional }}</td>
              </ng-container>
            </tr>
            <tr>
              <td class="title">Price Per {{ billType?.name }}: </td>
              <td class="info">${{job?.price_per_unit}}</td>
              <ng-container *ngIf="billTypeOptional">
                <td class="title">Price Per {{ billTypeOptional?.name }}: </td>
                <td class="info">${{job?.price_per_unit_optional}}</td>
              </ng-container>
            </tr>
            <tr>
              <td class="title">Total {{ billType?.name }} Price: </td>
              <td class="info">${{totalPrice}}</td>
              <ng-container *ngIf="billTypeOptional">
                <td class="title">Total {{ billTypeOptional?.name }} Price: </td>
                <td class="info">${{totalPriceOptional}}</td>
              </ng-container>
            </tr>
            <tr>
              <ng-container *ngIf="billTypeOptional">
                <td></td>
                <td></td>
              </ng-container>
              <td class="title">Total Price:</td>
              <td class="info">${{totalPrice}}</td>
            </tr>
          </table>
        </div>
  
      </div>
  
      <div class="images">
        <ng-container *ngFor="let image of images">
          <img src="{{image}}">
        </ng-container>
  
      </div>
  
    </div>
  </div>
</div>
