/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./callback.component";
import * as i3 from "@angular/router";
import * as i4 from "../authentication.service";
import * as i5 from "../../common/api.service";
var styles_CallbackComponent = [i0.styles];
var RenderType_CallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallbackComponent, data: {} });
export { RenderType_CallbackComponent as RenderType_CallbackComponent };
export function View_CallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" callback works!\n"]))], null, null); }
export function View_CallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-callback", [], null, null, null, View_CallbackComponent_0, RenderType_CallbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.CallbackComponent, [i3.ActivatedRoute, i4.AuthenticationService, i5.ApiService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallbackComponentNgFactory = i1.ɵccf("app-callback", i2.CallbackComponent, View_CallbackComponent_Host_0, {}, {}, []);
export { CallbackComponentNgFactory as CallbackComponentNgFactory };
