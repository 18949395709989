import { BehaviorSubject, fromEvent } from "rxjs";
import * as i0 from "@angular/core";
var ConnectionService = /** @class */ (function () {
    function ConnectionService() {
        var _this = this;
        this.subscriptions = [];
        this.connectionChange = new BehaviorSubject(true);
        // subscribe to connection status
        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.isOnline = navigator.onLine;
        if (!this.isOnline) {
            this.emitConnectionChange(false);
        }
        this.subscriptions.push(this.onlineEvent.subscribe(function (e) {
            _this.isOnline = true;
            _this.emitConnectionChange(true);
        }));
        this.subscriptions.push(this.offlineEvent.subscribe(function (e) {
            _this.isOnline = false;
            _this.emitConnectionChange(false);
        }));
    }
    ConnectionService.prototype.emitConnectionChange = function (online) {
        this.connectionChange.next(online);
    };
    // call on driver module destroy
    ConnectionService.prototype.onDestroy = function (event) {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    ConnectionService.ngInjectableDef = i0.defineInjectable({ factory: function ConnectionService_Factory() { return new ConnectionService(); }, token: ConnectionService, providedIn: "root" });
    return ConnectionService;
}());
export { ConnectionService };
