import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../common/api.service';
import { CrystalLightbox } from 'ngx-crystal-gallery';
import { LoadService, LoadImage } from '../../../common/load.service';
import imageCompression from 'browser-image-compression';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-load-images',
    templateUrl: './load-images.component.html',
    styleUrls: ['./load-images.component.scss']
})
export class LoadImagesComponent implements OnInit {

    @Input() public isBolComplete = false;

    @Input()
    public images = [];

    @Input()
    public status;

    @Input()
    public archived = false;

    @Input()
    public sync: Subject<any> = null;

    @Input()
    public loadId;

    @Output()
    public loadImageChange = new EventEmitter();

    public galleryImages = [];
    public online = false;
    public hasChanged = false;
    public loading = false;
    public viewImageFail = false;

    constructor(private apiService: ApiService, private loadService: LoadService, private lightbox: CrystalLightbox) {
    }

    ngOnInit() {
        this.online = navigator.onLine;
        this.sync.subscribe((online) => {
            this.online = online;
            if ( online === true ) {
                this.saveChanges();
           }
        });
    }

    view(image) {
        if (image.id === undefined) {
            this.lightbox.open([{full: image.source}], {loop: true});
            return;
        }
        if (!this.online) {
            this.viewImageFail = true;
            setTimeout( () => this.viewImageFail = false, 6000);
            return;
        }
        this.apiService.get(`load-images/${image.id}`, {
            responseType: 'blob',
            headers: {
                accept: 'image/*'
            }
        }).subscribe((response: any) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.lightbox.open([{ full: reader.result}], {loop: true});
            }, false);

            if (response) {
                reader.readAsDataURL(response);
            }
        });

    }

    async addPicture(event) {
         if (event.target.files && event.target.files.length) {
            for (let file of event.target.files) {
                let reader = new FileReader();
                reader.readAsDataURL(file);

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                    maxIteration: 10
                };

                try {
                    const compressedFile = await imageCompression(file, options);

                    const scaledImage = await imageCompression.getDataUrlFromFile(compressedFile);

                    const createdImage =  new Object({
                        source: scaledImage,
                        name: file.name,
                        url: 'none' // the model is dumb and has the url as required, so  we are giving it a dummy string
                    });

                    // check if it's online, if not, then store in the list and save it later.
                    if (this.online) {
                        this.loading = true;
                        this.loadService.createLoadImage(this.loadId, createdImage as LoadImage).pipe(
                            finalize(() => this.loading = false)
                        ).subscribe((image) => {
                            this.images.push(image);
                        });
                    } else {
                        this.hasChanged = true;
                        this.images.push(createdImage);
                        this.loadImageChange.emit('changed');
                    }

                } catch (error) {
                    throw error;
                }
            }
        }
    }

    public destroy(image) {
        const index = this.images.indexOf(image, 0);

        if (!('id' in image)) {
            this.images.splice(index, 1);
        } else {
            this.loading = true;
            this.loadService.deleteLoadImage(image.id).pipe(
                finalize(() => {
                    this.loading = false;
                })
            ).subscribe(() => {
                this.images.splice(index, 1);
                this.galleryImages.splice(index, 1);
            }, () => {

            });
        }


    }

    saveChanges() {
        if (this.hasChanged && this.images.length !== 0) {
            this.images.forEach(image => {
                if (!('id' in image)) {
                    const index = this.images.indexOf(image, 0);
                    this.loading = true;
                    this.loadService.createLoadImage(this.loadId, image as LoadImage).pipe(
                        finalize(() => this.loading = false)
                    ).subscribe((savedImage) => {
                        this.images.splice(index, 1);
                        this.images.push(savedImage);
                    });
                }
            });
            this.loadImageChange.emit('done');
        }
    }
}
