/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../comments/comments.component.ngfactory";
import * as i4 from "../comments/comments.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "../../../common/bol-comment.service";
import * as i8 from "../../../common/bol.service";
import * as i9 from "@angular/flex-layout/flex";
import * as i10 from "@angular/flex-layout/core";
import * as i11 from "./comment-modal.component";
var styles_CommentModalComponent = [i0.styles];
var RenderType_CommentModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentModalComponent, data: {} });
export { RenderType_CommentModalComponent as RenderType_CommentModalComponent };
export function View_CommentModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Comments"])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["container", 1]], null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-comments", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CommentsComponent_0, i3.RenderType_CommentsComponent)), i1.ɵdid(7, 638976, null, 0, i4.CommentsComponent, [i5.FormBuilder, i6.MatSnackBar, i7.BolCommentService, i8.BolService], { bol: [0, "bol"], archived: [1, "archived"] }, { close: "close" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "dialog-actions mat-dialog-actions"], ["fxLayoutAlign", "end end"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(9, 671744, null, 0, i9.DefaultLayoutAlignDirective, [i1.ElementRef, i10.StyleUtils, [2, i9.LayoutAlignStyleBuilder], i10.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(10, 16384, null, 0, i2.MatDialogActions, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.data.bol; var currVal_2 = _co.data.archived; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = "end end"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); }); }
export function View_CommentModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comment-modal", [], null, null, null, View_CommentModalComponent_0, RenderType_CommentModalComponent)), i1.ɵdid(1, 8437760, null, 0, i11.CommentModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var CommentModalComponentNgFactory = i1.ɵccf("app-comment-modal", i11.CommentModalComponent, View_CommentModalComponent_Host_0, {}, {}, []);
export { CommentModalComponentNgFactory as CommentModalComponentNgFactory };
