/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./past-bill-of-lading-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/common";
import * as i12 from "../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i13 from "@angular/material/paginator";
import * as i14 from "./past-bill-of-lading-list.component";
import * as i15 from "../../common/bol.service";
var styles_PastBillOfLadingListComponent = [i0.styles];
var RenderType_PastBillOfLadingListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PastBillOfLadingListComponent, data: {} });
export { RenderType_PastBillOfLadingListComponent as RenderType_PastBillOfLadingListComponent };
function View_PastBillOfLadingListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "mat-list-item", [["class", "archived-bol-card mat-elevation-z1 mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 8, "div", [["class", "archived-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h3", [["class", "mat-line"], ["matLine", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(10, null, ["Job #", " - BOL #", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [["class", "mat-line"], ["matLine", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(13, null, ["Finished At: ", ""])), (_l()(), i1.ɵeld(14, 0, null, 2, 4, "button", [["class", "view-button"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(15, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 2), i1.ɵdid(17, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Show Bol"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "/bols/archived", _v.context.$implicit.id); _ck(_v, 6, 0, currVal_2); var currVal_8 = _ck(_v, 16, 0, "/view-bol", _v.context.$implicit.id); _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.job.number; var currVal_4 = _v.context.$implicit.number; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.finished_at_formatted; _ck(_v, 13, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 17).disabled || null); var currVal_7 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_6, currVal_7); }); }
export function View_PastBillOfLadingListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "past-bol-list-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Completed Bills of Lading"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(4, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PastBillOfLadingListComponent_1)), i1.ɵdid(6, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "mat-paginator", [["class", "mat-elevation-z1 mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.pageEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatPaginator_0, i12.RenderType_MatPaginator)), i1.ɵdid(8, 245760, null, 0, i13.MatPaginator, [i13.MatPaginatorIntl, i1.ChangeDetectorRef], { length: [0, "length"], pageSize: [1, "pageSize"], pageSizeOptions: [2, "pageSizeOptions"] }, { page: "page" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bols; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.length; var currVal_2 = _co.pageSize; var currVal_3 = _co.pageSizeOptions; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PastBillOfLadingListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-past-bill-of-lading-list", [], null, null, null, View_PastBillOfLadingListComponent_0, RenderType_PastBillOfLadingListComponent)), i1.ɵdid(1, 114688, null, 0, i14.PastBillOfLadingListComponent, [i15.BolService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PastBillOfLadingListComponentNgFactory = i1.ɵccf("app-past-bill-of-lading-list", i14.PastBillOfLadingListComponent, View_PastBillOfLadingListComponent_Host_0, {}, {}, []);
export { PastBillOfLadingListComponentNgFactory as PastBillOfLadingListComponentNgFactory };
