import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ApiService } from '../../../common/api.service';
import { CrystalLightbox } from 'ngx-crystal-gallery';
import { LoadService } from '../../../common/load.service';
import imageCompression from 'browser-image-compression';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
var LoadImagesComponent = /** @class */ (function () {
    function LoadImagesComponent(apiService, loadService, lightbox) {
        this.apiService = apiService;
        this.loadService = loadService;
        this.lightbox = lightbox;
        this.isBolComplete = false;
        this.images = [];
        this.archived = false;
        this.sync = null;
        this.loadImageChange = new EventEmitter();
        this.galleryImages = [];
        this.online = false;
        this.hasChanged = false;
        this.loading = false;
        this.viewImageFail = false;
    }
    LoadImagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.online = navigator.onLine;
        this.sync.subscribe(function (online) {
            _this.online = online;
            if (online === true) {
                _this.saveChanges();
            }
        });
    };
    LoadImagesComponent.prototype.view = function (image) {
        var _this = this;
        if (image.id === undefined) {
            this.lightbox.open([{ full: image.source }], { loop: true });
            return;
        }
        if (!this.online) {
            this.viewImageFail = true;
            setTimeout(function () { return _this.viewImageFail = false; }, 6000);
            return;
        }
        this.apiService.get("load-images/" + image.id, {
            responseType: 'blob',
            headers: {
                accept: 'image/*'
            }
        }).subscribe(function (response) {
            var reader = new FileReader();
            reader.addEventListener('load', function () {
                _this.lightbox.open([{ full: reader.result }], { loop: true });
            }, false);
            if (response) {
                reader.readAsDataURL(response);
            }
        });
    };
    LoadImagesComponent.prototype.addPicture = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, file, reader, options, compressedFile, scaledImage, createdImage, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(event.target.files && event.target.files.length)) return [3 /*break*/, 7];
                        _i = 0, _a = event.target.files;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 7];
                        file = _a[_i];
                        reader = new FileReader();
                        reader.readAsDataURL(file);
                        options = {
                            maxSizeMB: 1,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                            maxIteration: 10
                        };
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, imageCompression(file, options)];
                    case 3:
                        compressedFile = _b.sent();
                        return [4 /*yield*/, imageCompression.getDataUrlFromFile(compressedFile)];
                    case 4:
                        scaledImage = _b.sent();
                        createdImage = new Object({
                            source: scaledImage,
                            name: file.name,
                            url: 'none' // the model is dumb and has the url as required, so  we are giving it a dummy string
                        });
                        // check if it's online, if not, then store in the list and save it later.
                        if (this.online) {
                            this.loading = true;
                            this.loadService.createLoadImage(this.loadId, createdImage).pipe(finalize(function () { return _this.loading = false; })).subscribe(function (image) {
                                _this.images.push(image);
                            });
                        }
                        else {
                            this.hasChanged = true;
                            this.images.push(createdImage);
                            this.loadImageChange.emit('changed');
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _b.sent();
                        throw error_1;
                    case 6:
                        _i++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    LoadImagesComponent.prototype.destroy = function (image) {
        var _this = this;
        var index = this.images.indexOf(image, 0);
        if (!('id' in image)) {
            this.images.splice(index, 1);
        }
        else {
            this.loading = true;
            this.loadService.deleteLoadImage(image.id).pipe(finalize(function () {
                _this.loading = false;
            })).subscribe(function () {
                _this.images.splice(index, 1);
                _this.galleryImages.splice(index, 1);
            }, function () {
            });
        }
    };
    LoadImagesComponent.prototype.saveChanges = function () {
        var _this = this;
        if (this.hasChanged && this.images.length !== 0) {
            this.images.forEach(function (image) {
                if (!('id' in image)) {
                    var index_1 = _this.images.indexOf(image, 0);
                    _this.loading = true;
                    _this.loadService.createLoadImage(_this.loadId, image).pipe(finalize(function () { return _this.loading = false; })).subscribe(function (savedImage) {
                        _this.images.splice(index_1, 1);
                        _this.images.push(savedImage);
                    });
                }
            });
            this.loadImageChange.emit('done');
        }
    };
    return LoadImagesComponent;
}());
export { LoadImagesComponent };
