import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var UserService = /** @class */ (function () {
    function UserService(apiService) {
        this.apiService = apiService;
    }
    UserService.prototype.find = function (sortOrder, pageNumber, limit, filters) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        var options = {
            params: {
                // sortOrder: sortOrder,
                page: pageNumber.toString(),
                limit: limit.toString(),
                role: 'all'
            }
        };
        if (filters !== null) {
            if (filters.role !== 'all') {
                options.params.role = filters.role;
            }
            if (typeof filters.search !== 'undefined') {
                options.params['search'] = filters.search;
            }
        }
        return this.apiService.get('users', options);
    };
    UserService.prototype.getAvailableDrivers = function (status, department, search, page) {
        var uri = "schedulable-drivers?page=" + page + "&status=" + status + "&search=" + search + "&department=" + department;
        return this.apiService.get(uri);
    };
    UserService.prototype.findOne = function (id) {
        return this.apiService.get('users/' + id).pipe(map(function (res) { return res['data']; }));
    };
    UserService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('users', payload);
        }
        return this.apiService.put('users/' + id, payload);
    };
    UserService.prototype.destroy = function (id) {
        return this.apiService.delete('users/' + id);
    };
    UserService.prototype.findDispatchers = function (options) {
        return this.apiService.get("dispatchers", options);
    };
    UserService.prototype.uploadAvatar = function (payload) {
        return this.apiService.post('me/avatar', payload);
    };
    UserService.prototype.getMe = function () {
        return this.apiService.get('me');
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ApiService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
