import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { debounceTime, finalize, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { LocationsService } from '../../../common/location.service';
import { LocationTypesService } from '../../../common/location-types.service';
import { WellService } from '../../../common/well.service';
import { ValidateCoordinates } from '../../../common/coordinates.validator';
import * as Coordinates from 'coordinate-parser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-load-route',
    templateUrl: './load-route.component.html',
    styleUrls: ['./load-route.component.scss']
})
export class LoadRouteComponent implements OnInit {

    public loading = false;
    public form;

    public wells = [];
    public filteredWells = [];
    public filteredDropoffLocations = [];
    public filteredPickupLocations = [];
    public areWellsLoading = false;
    public areDropoffLocationsLoading = false;
    public arePickupLocationsLoading = false;
    public types = [];

    public submitted = false;

    public locationFields = {
        pickup_well: false,
        dropoff_well: false,
        pickup_address: false,
        dropoff_address: false,
        pickup_location: false,
        dropoff_location: false
    };

    @Output() submit = new EventEmitter();

    public locationTypes$;

    @ViewChild('pickupAddressInput') pickupAddressInput;
    @ViewChild('dropoffAddressInput') dropoffAddressInput;

    constructor(private formBuilder: FormBuilder,
                public dialogRef: MatDialogRef<LoadRouteComponent>,
                private wellService: WellService,
                private cdr: ChangeDetectorRef,
                private locationsService: LocationsService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private locationTypesService: LocationTypesService) {
    }

    ngOnInit() {

        // this is weird
        this.locationTypes$ = this.locationTypesService.find().pipe(shareReplay(1), tap((response: any) => {
            this.types = response.data;
        }));

        this.locationTypes$.subscribe();

        // this is ok
        this.form = this.formBuilder.group({
            pickup_type: 'gps',
            dropoff_type: 'gps',
            pickup_name: [null, Validators.required],
            pickup_location: [null, Validators.compose([Validators.required, ValidateCoordinates])],
            dropoff_name: [null, Validators.required],
            dropoff_location: [null, Validators.compose([Validators.required, ValidateCoordinates])],
            pickup_well: null,
            dropoff_well: null,
            pickup_address: null,
            dropoff_address: null,
            pickup_dynamic_location: null,
            dropoff_dynamic_location: null
        });

        this.form.get('pickup_type').valueChanges.subscribe(change => {
            this.onTypeChange('pickup', change);
        });

        this.form.get('dropoff_type').valueChanges.subscribe(change => {
            this.onTypeChange('dropoff', change);
        });

        this.form
            .get('pickup_well')
            .valueChanges
            .pipe(
                startWith(''),
                debounceTime(600),
                tap(() => this.areWellsLoading = true),
                switchMap(value => this.wellChanged(value, 'pickup')
                    .pipe(
                        finalize(() => this.areWellsLoading = false)
                    ))
            ).subscribe(customers => this.filteredWells = customers.results);

        this.form
            .get('dropoff_well')
            .valueChanges
            .pipe(
                startWith(''),
                debounceTime(600),
                tap(() => this.areWellsLoading = true),
                switchMap(value => this.wellChanged(value, 'dropoff')
                    .pipe(
                        finalize(() => this.areWellsLoading = false)
                    ))
            ).subscribe(customers => this.filteredWells = customers.results);

        this.form
            .get('dropoff_dynamic_location')
            .valueChanges
            .pipe(
                debounceTime(600),
                tap(() => this.areDropoffLocationsLoading = true),
                switchMap(value => this.dynamicLocationChanged(value, 'dropoff')
                    .pipe(
                        finalize(() => this.areDropoffLocationsLoading = false)
                    ))
            ).subscribe((locations: any) => this.filteredDropoffLocations = locations.data);

        this.form
            .get('pickup_dynamic_location')
            .valueChanges
            .pipe(
                debounceTime(600),
                tap(() => this.arePickupLocationsLoading = true),
                switchMap(value => this.dynamicLocationChanged(value, 'pickup')
                    .pipe(
                        finalize(() => this.arePickupLocationsLoading = false)
                    ))
            ).subscribe((locations: any) => this.filteredPickupLocations = locations.data);

            // we should do all of this in a function
        if (this.data.route) {
            const route = this.data.route;

            // we should NOT do this, it's already being subscribed to, but how do we wait for it to load? that's the question
            this.locationTypes$.subscribe(response => {
                const dropoffType = route.dropoff_type;
                if (dropoffType === 'location') {
                    // this is for sure breaking stuff
                    const dropoffIndex = this.types.findIndex(type => type.id === route.dropoff_location_id);

                    if (dropoffIndex > -1) {
                        route.dropoff_type = this.types[dropoffIndex];
                    }
                }

                const pickupType = route.pickup_type;
                if (pickupType === 'location') {
                    const pickupIndex = this.types.findIndex(type => type.id === route.pickup_location_id);

                    if (pickupIndex > -1) {
                        route.pickup_type = this.types[pickupIndex];
                    }
                }

                this.form.patchValue({
                    pickup_name: route.pickup_name,
                    pickup_location: route.pickup_location,
                    pickup_type: route.pickup_type,
                    pickup_well: route.pickup_well,
                    pickup_address: route.pickup_address,
                    pickup_dynamic_location: route.pickup_dynamic_location,
                    dropoff_name: route.dropoff_name,
                    dropoff_location: route.dropoff_location,
                    dropoff_type: route.dropoff_type,
                    dropoff_well: route.dropoff_well,
                    dropoff_address: route.dropoff_address,
                    dropoff_dynamic_location: route.dropoff_dynamic_location
                });

                this.cdr.detectChanges();

                if (route.pickup_type === 'address') {
                    this.pickupAddressInput.nativeElement.value = route.pickup_address;
                }

                if (route.dropoff_type === 'address') {
                    this.dropoffAddressInput.nativeElement.value = route.dropoff_address;
                }
            });


        }
    }

    onNoClick() {
        const form = this.collectData();
        this.dialogRef.close({success: false, route: form});
    }

    onTypeChange(type, change) {

        const nameField = `${type}_name`;
        const locationField = `${type}_location`;

        const addressField = `${type}_address`;
        const wellField = `${type}_well`;
        const dynamicLocationField = `${type}_dynamic_location`;

        const patch = {};
        patch[addressField] = null;
        patch[wellField] = null;
        patch[dynamicLocationField] = null;
        this.form.patchValue(patch);

        // if not manual
        if (change.id || change === 'well') {
            this.form.get(nameField).disable();
            this.form.get(locationField).disable();
        } else if (change === 'address') {
            this.form.get(nameField).enable();
            this.form.get(locationField).disable();
        } else {
            this.form.get(nameField).enable();
            this.form.get(locationField).enable();
            this.setupLocationFields('gps', type);
            return;
        }

        // dynamic location type
        if (change.id) {
            this.setupLocationFields('location', type);
            this.form.get(`${type}_dynamic_location`).setValue('');
            return;
        }

        this.setupLocationFields(change, type);
    }

    wellChanged(value, correspondingLocationField) {

        if (this.form.get(`${correspondingLocationField}_type`).value !== 'well') {
            return of({
                data: []
            });
        }
        if (value && value.id) {

            this.wellService.find().subscribe((response: any) => {
                this.wells = response.data;
            });

            this.form.get(`${correspondingLocationField}_location`).setValue(value.location);
            this.form.get(`${correspondingLocationField}_name`).setValue(value.name);
        }

        return this.wellService.searchWells(value);
    }

    dynamicLocationChanged(value, correspondingLocationField) {

        const locationType = this.form.get(`${correspondingLocationField}_type`).value;
        if (locationType === 'well' || locationType === 'gps' || locationType === 'address') {
            return of({
                data: []
            });
        }

        if (value !== undefined && value !== null && value.id) {
            this.form.get(`${correspondingLocationField}_location`).setValue(value.location);
            this.form.get(`${correspondingLocationField}_name`).setValue(value.name);
            return of({
                data: [value]
            });
        }

        const type = this.form.get(`${correspondingLocationField}_type`).value;
        if (value !== undefined && type !== null && type.id) {
            return this.locationsService.getByType(type.id, value);
        }

        return of({
            data: []
        });
    }

    wellDisplayFn(well
                      :
                      any
    ) {
        if (well) {
            return well.name;
        }
    }

    dynamicLocationDisplayFn(location
                                 :
                                 any
    ) {
        if (location) {
            return location.name;
        }
    }

    collectData() {
        const form = this.form.getRawValue();

        try {
            const pickupPosition = new Coordinates(form.pickup_location);
            form.pickup_location = pickupPosition.latitude + ',' + pickupPosition.longitude;

            const dropoffPosition = new Coordinates(form.dropoff_location);
            form.dropoff_location = dropoffPosition.latitude + ',' + dropoffPosition.longitude;
        } catch (error) {
            return;
        }

        if (!this.form.valid) {
            return;
        }

        if (form.pickup_type.id) {
            form.pickup_type = 'location';
        }

        if (form.dropoff_type.id) {
            form.dropoff_type = 'location';
        }

        return form;
    }


    onSubmit() {
        this.submitted = true;

        const form = this.collectData();

        this.dialogRef.close({success: true, route: form});
    }

    onPickupAutocompleteSelected(result) {
        this.form.patchValue({
            pickup_address: result.formatted_address
        });
    }

    onPickupLocationSelected(location) {
        this.form.patchValue({
            pickup_location: `${location.latitude}, ${location.longitude}`,
        });
    }

    onDropoffAutocompleteSelected(result) {
        this.form.patchValue({
            dropoff_address: result.formatted_address
        });
    }

    onDropoffLocationSelected(location) {
        this.form.patchValue({
            dropoff_location: `${location.latitude}, ${location.longitude}`,
        });
    }

    setupLocationFields(field, type) {
        switch (field) {
            case 'address':
                this.toggleLocationField(`${type}_address`, type);
                break;
            case 'gps':
                this.toggleLocationField(null, type);
                break;
            case 'well':
                this.toggleLocationField(`${type}_well`, type);
                break;
            case 'location':
                this.toggleLocationField(`${type}_location`, type);
                break;
        }
    }

    toggleLocationField(field, type) {
        this.hideAllFields(type);

        if (field !== null) {
            this.locationFields[field] = true;
        }
    }

    hideAllFields(type) {
        this.locationFields[`${type}_address`] = false;
        this.locationFields[`${type}_well`] = false;
        this.locationFields[`${type}_location`] = false;
    }

}
