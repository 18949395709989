import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";

@Component({
    selector: 'app-locations-modal',
    templateUrl: './locations-modal.component.html',
    styleUrls: ['./locations-modal.component.scss']
})
export class LocationsModalComponent {


    public route;

    public mapsLink;

    public currentPosition;

    constructor(public dialogRef: MatDialogRef<LocationsModalComponent>,
                private snackbar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.route = data.route;

        this.mapsLink = `https://www.google.com/maps/dir/?api=1&origin=${this.route.pickup_location}&destination=${this.route.dropoff_location}&travelmode=driving`;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.currentPosition = position.coords.latitude + ',' + position.coords.longitude;
                this.mapsLink = `https://www.google.com/maps/dir/?api=1&origin=${this.currentPosition}&waypoints=${this.route.pickup_location}&destination=${this.route.dropoff_location}&travelmode=driving`;
            });
        }

    }

    onClose(): void {
        this.dialogRef.close({});
    }

    onCopy(coordinates): void {
        const el = document.createElement('textarea');
        el.value = coordinates;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.snackbar.open('Coordinates copied to clipboard', 'Close', {
            duration: 3000
        });
    }


}
