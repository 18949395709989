/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./connection-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./connection-status.component";
var styles_ConnectionStatusComponent = [i0.styles];
var RenderType_ConnectionStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConnectionStatusComponent, data: {} });
export { RenderType_ConnectionStatusComponent as RenderType_ConnectionStatusComponent };
function View_ConnectionStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "offline-notification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You are offline. All changes will be cached and synced to the server when connection is restored. "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Dismiss"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_ConnectionStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isOnline && !_co.isDismissed); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConnectionStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-connection-status", [], null, null, null, View_ConnectionStatusComponent_0, RenderType_ConnectionStatusComponent)), i1.ɵdid(1, 245760, null, 0, i8.ConnectionStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConnectionStatusComponentNgFactory = i1.ɵccf("app-connection-status", i8.ConnectionStatusComponent, View_ConnectionStatusComponent_Host_0, {}, {}, []);
export { ConnectionStatusComponentNgFactory as ConnectionStatusComponentNgFactory };
