<h1 mat-dialog-title>Locations</h1>
<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="none" fxFlexAlign="none">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
            <h2>Pickup</h2>
            <p fxFlex="100">
                <b>Pickup Name: </b> {{ route?.pickup_name }}
            </p>
            <form fxFlex="100">
                <mat-form-field fxFlex="100">
                    <input [readonly]="true" matInput placeholder="Pickup Coordinates" fxFlex="100" [value]="route?.pickup_location" />
                    <button mat-button matSuffix (click)="onCopy(route?.pickup_location)">
                        <mat-icon>
                            assignment
                        </mat-icon>
                        Copy
                    </button>
                </mat-form-field>
            </form>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
            <h2>Dropoff</h2>
            <p fxFlex="100">
                <b>Dropoff Name: </b> {{ route?.dropoff_name }}
            </p>
            <form fxFlex="100">
                <mat-form-field fxFlex="100">
                    <input [readonly]="true" matInput placeholder="Dropoff Coordinates"  [value]="route?.dropoff_location" />
                    <button mat-button matSuffix (click)="onCopy(route?.dropoff_location)">
                        <mat-icon>
                            assignment
                        </mat-icon>
                        Copy
                    </button>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>

<div mat-dialog-actions class="dialog-actions" fxLayoutAlign="end end">
    <a style="margin-left: 10px;" target="_blank" rel="noopener noreferrer"  [href]="mapsLink" mat-flat-button>
        View in Google Maps
    </a>
    <button mat-flat-button (click)="onClose()" color="primary">Close</button>
</div>
