import { ApiService } from "./api.service";
import { BehaviorSubject, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(apiService) {
        this.apiService = apiService;
        this.unreadNotifications$ = new BehaviorSubject([]);
        this.markAllAsReadSubject = new Subject();
        this.markAsReadSubject = new Subject();
        this.unreadNotifications = [];
    }
    NotificationService.prototype.onMarkAllAsRead = function () {
        return this.markAllAsReadSubject;
    };
    NotificationService.prototype.onMarkAsRead = function () {
        return this.markAsReadSubject;
    };
    NotificationService.prototype.addUnreadNotification = function (notification, read, broadcast) {
        if (read === void 0) { read = false; }
        if (broadcast === void 0) { broadcast = false; }
        notification.read = read;
        this.unreadNotifications.push(notification);
        if (broadcast === true) {
            this.unreadNotifications$.next(this.unreadNotifications);
        }
    };
    NotificationService.prototype.fetchUnread = function () {
        var _this = this;
        this.unreadNotifications = [];
        return this.apiService.get('me/notifications?type=unread').pipe(tap(function (response) {
            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                var notification = response_1[_i];
                _this.addUnreadNotification(notification, notification.read_at !== null);
            }
            _this.unreadNotifications$.next(_this.unreadNotifications);
        }));
    };
    NotificationService.prototype.fetchAll = function (page) {
        if (page === void 0) { page = 1; }
        return this.apiService.get("me/notifications?page=" + page);
    };
    NotificationService.prototype.markAllAsRead = function (from) {
        var _this = this;
        return this.apiService.put('me/notifications').pipe(tap(function () {
            _this.markAllAsReadSubject.next({
                from: from
            });
            _this.unreadNotifications = [];
            _this.unreadNotifications$.next(_this.unreadNotifications);
        }));
    };
    NotificationService.prototype.deleteAllRead = function () {
        return this.apiService.delete('me/notifications');
    };
    NotificationService.prototype.markAsRead = function (notification, from) {
        var _this = this;
        return this.apiService.put("me/notifications/" + notification.id, {
            read: true
        }).pipe(tap(function () {
            _this.markAsReadSubject.next({
                from: from,
                id: notification.id
            });
            var index = _this.unreadNotifications.findIndex(function (element) { return element.id === notification.id; });
            if (index != -1) {
                _this.unreadNotifications.splice(index, 1);
                _this.unreadNotifications$.next(_this.unreadNotifications);
            }
        }));
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ApiService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
