import { AfterViewInit, Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {TruckTableDataSource} from './truck-table-datasource';
import {TruckService} from "../../../common/truck.service";
import {tap} from "rxjs/operators";
import { FormBuilder } from "@angular/forms";

@Component({
    selector: 'app-truck-table',
    templateUrl: './truck-table.component.html',
    styleUrls: ['./truck-table.component.scss']
})
export class TruckTableComponent implements OnInit, AfterViewInit {

    @Output() save = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: TruckTableDataSource;
    pageCount = 0;

    @Input() selectedTruck;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['truck_number', 'license', 'bol_count', 'actions'];

    public filterForm;

    constructor(private truckService: TruckService,
                private formBuilder: FormBuilder,
                private snackbar: MatSnackBar) {}

    ngOnInit() {
        this.dataSource = new TruckTableDataSource(this.truckService);
        this.dataSource.load(1, 25);

        this.filterForm = this.formBuilder.group({
            search: ''
        });
    }

    applyFilters() {

    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onSave(truck) {
        this.save.emit(truck);
    }
}
