import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs/internal/operators/tap';
import * as Sentry from '@sentry/browser';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private _user = null;

    get user() {
        return this._user;
    }

    set user(user) {
        this._user = user;
        // Add a little extra sentry context
        Sentry.configureScope(scope => {
            scope.setUser({
                id: user.id,
                role: user.role,
                title: user.title,
                email: user.email,
            });
        });
    }

    constructor(private apiService: ApiService) {

    }

    public fetch() {
        let user$ = this.apiService.get('me');

        return user$.pipe(tap((response => {
            this.user = response;
        })));
    }
}
