import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.baseUrl = environment.api_url;
    }
    ApiService.prototype.get = function (uri, options) {
        if (options === void 0) { options = {}; }
        var url = this.getUrl(uri);
        var params = new HttpParams();
        for (var key in options.params) {
            params = params.set(key, options.params[key]);
        }
        options.params = params;
        return this.http.get(url, options);
    };
    ApiService.prototype.post = function (uri, payload, options) {
        if (payload === void 0) { payload = {}; }
        if (options === void 0) { options = {}; }
        var url = this.getUrl(uri);
        return this.http.post(url, payload, options);
    };
    ApiService.prototype.put = function (uri, payload, options) {
        if (payload === void 0) { payload = {}; }
        if (options === void 0) { options = {}; }
        var url = this.getUrl(uri);
        return this.http.put(url, payload, options);
    };
    ApiService.prototype.delete = function (uri, options) {
        if (options === void 0) { options = {}; }
        var url = this.getUrl(uri);
        return this.http.delete(url, options);
    };
    ApiService.prototype.getUrl = function (uri) {
        return this.baseUrl + "/" + uri;
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
