import { ApiService } from './api.service';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var WellService = /** @class */ (function () {
    function WellService(apiService) {
        this.apiService = apiService;
    }
    WellService.prototype.findByRig = function (rigId) {
        return this.apiService.get("rigs/" + rigId + "/wells");
    };
    WellService.prototype.findByCustomer = function (customerId) {
        return this.apiService.get("wells", {
            params: {
                customer: customerId
            }
        });
    };
    WellService.prototype.find = function (sortOrder, pageNumber, limit, filters, mode) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (filters === void 0) { filters = null; }
        if (mode === void 0) { mode = 'paged'; }
        var options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
                search: '',
                mode: mode
            }
        };
        if (filters !== null) {
            if (filters.status) {
                options.params['status'] = filters.status;
            }
            if (filters.search !== '') {
                options.params.search = filters.search;
            }
        }
        return this.apiService.get('wells', options);
    };
    WellService.prototype.findOne = function (id) {
        return this.apiService.get('wells/' + id).pipe(map(function (res) { return res['data']; }));
    };
    WellService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('wells', payload);
        }
        return this.apiService.put('wells/' + id, payload);
    };
    WellService.prototype.destroy = function (id) {
        return this.apiService.delete('wells/' + id);
    };
    WellService.prototype.searchWells = function (name) {
        if (name === void 0) { name = null; }
        if (name == null || name instanceof Object) {
            name = '';
        }
        return this.apiService.get('wells?search=' + name).pipe(tap(function (response) {
            response.results = response.data
                .map(function (well) {
                return { id: well.id, name: well.name, location: well.location };
            });
            return response;
        }));
    };
    WellService.prototype.search = function (name) {
        if (name === void 0) { name = null; }
        if (name == null || name instanceof Object) {
            name = '';
        }
        return this.apiService.get('rigs?search=' + name).pipe(tap(function (response) {
            response.results = response.data
                .map(function (rig) {
                return { id: rig.id, name: rig.name, customer: rig.customer };
            });
            return response;
        }));
    };
    WellService.ngInjectableDef = i0.defineInjectable({ factory: function WellService_Factory() { return new WellService(i0.inject(i1.ApiService)); }, token: WellService, providedIn: "root" });
    return WellService;
}());
export { WellService };
