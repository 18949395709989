/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bill-of-lading-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bill-of-lading/bill-of-lading.component.ngfactory";
import * as i3 from "./bill-of-lading/bill-of-lading.component";
import * as i4 from "../../common/bol.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/forms";
import * as i8 from "../../common/job-safety-analysis.service";
import * as i9 from "../../common/load.service";
import * as i10 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i11 from "@angular/material/tabs";
import * as i12 from "@angular/common";
import * as i13 from "./bill-of-lading-detail.component";
import * as i14 from "@angular/material/snack-bar";
import * as i15 from "../../common/notification.service";
var styles_BillOfLadingDetailComponent = [i0.styles];
var RenderType_BillOfLadingDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillOfLadingDetailComponent, data: {} });
export { RenderType_BillOfLadingDetailComponent as RenderType_BillOfLadingDetailComponent };
function View_BillOfLadingDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bill-of-lading", [], null, [[null, "reject"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reject" === en)) {
        var pd_0 = (_co.onBolReject($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BillOfLadingComponent_0, i2.RenderType_BillOfLadingComponent)), i1.ɵdid(1, 245760, null, 0, i3.BillOfLadingComponent, [i4.BolService, i1.NgZone, i5.Router, i6.MatDialog, i7.FormBuilder, i5.ActivatedRoute, i8.JobSafetyAnalysisService, i9.LoadService], { bolId: [0, "bolId"] }, { reject: "reject" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BillOfLadingDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [], null, null, null, i10.View_MatTab_0, i10.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i11.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[3, 2]], 0, 1, null, View_BillOfLadingDetailComponent_3)), i1.ɵdid(5, 16384, null, 0, i11.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(2, "Job ", _v.context.$implicit.job_number, " - BOL ", _v.context.$implicit.number, ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_BillOfLadingDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.selectedBolChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatTabGroup_0, i10.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i11.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i11.MAT_TABS_CONFIG]], { color: [0, "color"], selectedIndex: [1, "selectedIndex"] }, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingDetailComponent_2)), i1.ɵdid(4, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "warn"; var currVal_3 = _co.selectedIndex; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.links; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BillOfLadingDetailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-bill-of-lading", [], null, null, null, i2.View_BillOfLadingComponent_0, i2.RenderType_BillOfLadingComponent)), i1.ɵdid(2, 245760, null, 0, i3.BillOfLadingComponent, [i4.BolService, i1.NgZone, i5.Router, i6.MatDialog, i7.FormBuilder, i5.ActivatedRoute, i8.JobSafetyAnalysisService, i9.LoadService], { bolId: [0, "bolId"], archived: [1, "archived"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BillOfLadingDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingDetailComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingDetailComponent_4)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.archived; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.archived; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BillOfLadingDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bill-of-lading-detail", [], null, null, null, View_BillOfLadingDetailComponent_0, RenderType_BillOfLadingDetailComponent)), i1.ɵdid(1, 114688, null, 0, i13.BillOfLadingDetailComponent, [i4.BolService, i5.Router, i14.MatSnackBar, i15.NotificationService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BillOfLadingDetailComponentNgFactory = i1.ɵccf("app-bill-of-lading-detail", i13.BillOfLadingDetailComponent, View_BillOfLadingDetailComponent_Host_0, {}, {}, []);
export { BillOfLadingDetailComponentNgFactory as BillOfLadingDetailComponentNgFactory };
