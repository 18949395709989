<div class="past-bol-list-wrapper">
    <h1>Completed Bills of Lading</h1>
    <mat-list>

        <mat-list-item *ngFor="let bol of bols" class="archived-bol-card mat-elevation-z1">
                    <div class="archived-title" [routerLink]="['/bols/archived', bol.id]">
                        <h3 matLine>Job #{{ bol.job.number }} - BOL #{{ bol.number }}</h3>
                        <p matLine>Finished At: {{ bol.finished_at_formatted }}</p>
                    </div>
                    <button class="view-button" [routerLink]="['/view-bol', bol.id]" mat-stroked-button>Show Bol</button>
        </mat-list-item>
        <mat-paginator
                class="mat-elevation-z1"
                [length]="length"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent($event)">
        </mat-paginator>



    </mat-list>
</div>
