import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Subscription, Observable} from "rxjs";

@Component({
    selector: 'app-connection-status',
    templateUrl: './connection-status.component.html',
    styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {

    public isOnline = false;
    public isDismissed = false;

    private onlineEvent: Observable<Event>;
    private offlineEvent: Observable<Event>;

    subscriptions: Subscription[] = [];

    constructor() {}

    ngOnInit() {
        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.isOnline = navigator.onLine;

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
            this.isOnline = true;
            this.isDismissed = false;
        }));

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            this.isOnline = false;
        }));
    }

    ngOnDestroy() {
        /**
         * Unsubscribe all subscriptions to avoid memory leak
         */
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    dismiss() {
        this.isDismissed = true;
    }

}
