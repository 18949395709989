import {HostListener, Injectable} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ApiService} from "./api.service";
import {ConnectionService} from "./connection.service";

@Injectable({
    providedIn: 'root'
})
export class OfflineActionsService {

    queue = [];

    public isOnline: boolean;
    public onlineEvent;
    public offlineEvent;

    constructor(private localStorage: LocalStorage,
                private connectionService: ConnectionService,
                private apiService: ApiService) {
    }

    // call on driver module boot
    init() {
        // load queue
        this.loadQueue();

        this.isOnline = navigator.onLine;
        this.connectionService.connectionChange.subscribe(status => {
            this.isOnline = status;

            if(this.isOnline) {
                this.onConnectionRepair();
            }
        });
    }

    onConnectionRepair() {
        this.processQueue();
    }

    processQueue() {
        for(let item of this.queue) {
            if( ! this.isOnline) {
                break;
            }

            this.processItem(item);
        }
    }

    processItem(item) {
        if( ! this.validateQueueItem(item)) {
            this.removeItemFromQueue(item);
        }

        switch(item.method) {
            case 'post':
                this.apiService.post(item.uri, item.payload).subscribe((response: any) => {
                    this.removeItemFromQueue(item);
                }, () => {
                });

                break;
            case 'put':
                this.apiService.put(item.uri, item.payload).subscribe((response: any) => {
                    this.removeItemFromQueue(item);
                }, () => {
                });
                break;

            case 'delete':
                this.apiService.delete(item.uri).subscribe((response: any) => {
                    this.removeItemFromQueue(item);
                }, () => {
                });
                break;
        }

    }

    removeItemFromQueue(item) {
        const index = this.queue.indexOf(item, 0);
        if(index > - 1) {
            this.queue.splice(index, 1);
        }

        this.persistQueue();
    }

    private validateQueueItem(item) {
        return item.method && item.uri && item.type && item.payload;
    }

    // call on driver module destroy
    @HostListener("window:beforeunload", ["$event"])
    onDestroy(event) {
        this.persistQueue();
    }

    addToQueue(item) {
        this.queue.push(item);
        this.persistQueue();
    }

    loadQueue() {
        // load from localstorage
        this.localStorage.getItem('queue').subscribe((queue) => {
            this.queue = queue || [];

            if(this.isOnline) {
                this.onConnectionRepair();
            }

        }, () => {
        });
    }

    persistQueue() {
        // persist to localstorage
        this.localStorage.setItem('queue', this.queue).subscribe(() => {
        }, () => {
        });
    }

}
