/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notification-card/notification-card.component.ngfactory";
import * as i3 from "../notification-card/notification-card.component";
import * as i4 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i5 from "ngx-loading";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i12 from "@angular/material/list";
import * as i13 from "@angular/common";
import * as i14 from "../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i15 from "@angular/material/paginator";
import * as i16 from "./notifications.component";
import * as i17 from "../../common/notification.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-card", [], null, [[null, "read"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("read" === en)) {
        var pd_0 = (_co.onRead($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NotificationCardComponent_0, i2.RenderType_NotificationCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationCardComponent, [], { notification: [0, "notification"] }, { read: "read" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "wrapper no-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-loading", [], null, null, null, i4.View_NgxLoadingComponent_0, i4.RenderType_NgxLoadingComponent)), i1.ɵdid(2, 114688, null, 0, i5.NgxLoadingComponent, [i5.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "notification-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "primary"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markAllAsRead() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Mark all as read"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "warn"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAllRead() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete all read notification"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i11.View_MatList_0, i11.RenderType_MatList)), i1.ɵdid(13, 704512, null, 0, i12.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationsComponent_1)), i1.ɵdid(15, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "mat-paginator", [["class", "mat-elevation-z1 mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.pageEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatPaginator_0, i14.RenderType_MatPaginator)), i1.ɵdid(17, 245760, null, 0, i15.MatPaginator, [i15.MatPaginatorIntl, i1.ChangeDetectorRef], { length: [0, "length"], pageSize: [1, "pageSize"], pageSizeOptions: [2, "pageSizeOptions"] }, { page: "page" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 7, 0, currVal_3); var currVal_6 = "warn"; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.notifications; _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.length; var currVal_9 = _co.pageSize; var currVal_10 = _co.pageSizeOptions; _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 7).disabled || null); var currVal_2 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i16.NotificationsComponent, [i17.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i16.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
