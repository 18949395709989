import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var TruckService = /** @class */ (function () {
    function TruckService(apiService) {
        this.apiService = apiService;
        this.cachedGetAll$ = null;
    }
    TruckService.prototype.find = function (sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        return this.apiService.get('bol-trucks', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    };
    TruckService.prototype.findOne = function (id) {
        return this.apiService.get('trucks/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    TruckService.ngInjectableDef = i0.defineInjectable({ factory: function TruckService_Factory() { return new TruckService(i0.inject(i1.ApiService)); }, token: TruckService, providedIn: "root" });
    return TruckService;
}());
export { TruckService };
