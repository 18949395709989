import { LocalStorage } from "@ngx-pwa/local-storage";
import { ApiService } from "./api.service";
import { ConnectionService } from "./connection.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "./connection.service";
import * as i3 from "./api.service";
var OfflineActionsService = /** @class */ (function () {
    function OfflineActionsService(localStorage, connectionService, apiService) {
        this.localStorage = localStorage;
        this.connectionService = connectionService;
        this.apiService = apiService;
        this.queue = [];
    }
    // call on driver module boot
    OfflineActionsService.prototype.init = function () {
        var _this = this;
        // load queue
        this.loadQueue();
        this.isOnline = navigator.onLine;
        this.connectionService.connectionChange.subscribe(function (status) {
            _this.isOnline = status;
            if (_this.isOnline) {
                _this.onConnectionRepair();
            }
        });
    };
    OfflineActionsService.prototype.onConnectionRepair = function () {
        this.processQueue();
    };
    OfflineActionsService.prototype.processQueue = function () {
        for (var _i = 0, _a = this.queue; _i < _a.length; _i++) {
            var item = _a[_i];
            if (!this.isOnline) {
                break;
            }
            this.processItem(item);
        }
    };
    OfflineActionsService.prototype.processItem = function (item) {
        var _this = this;
        if (!this.validateQueueItem(item)) {
            this.removeItemFromQueue(item);
        }
        switch (item.method) {
            case 'post':
                this.apiService.post(item.uri, item.payload).subscribe(function (response) {
                    _this.removeItemFromQueue(item);
                }, function () {
                });
                break;
            case 'put':
                this.apiService.put(item.uri, item.payload).subscribe(function (response) {
                    _this.removeItemFromQueue(item);
                }, function () {
                });
                break;
            case 'delete':
                this.apiService.delete(item.uri).subscribe(function (response) {
                    _this.removeItemFromQueue(item);
                }, function () {
                });
                break;
        }
    };
    OfflineActionsService.prototype.removeItemFromQueue = function (item) {
        var index = this.queue.indexOf(item, 0);
        if (index > -1) {
            this.queue.splice(index, 1);
        }
        this.persistQueue();
    };
    OfflineActionsService.prototype.validateQueueItem = function (item) {
        return item.method && item.uri && item.type && item.payload;
    };
    // call on driver module destroy
    OfflineActionsService.prototype.onDestroy = function (event) {
        this.persistQueue();
    };
    OfflineActionsService.prototype.addToQueue = function (item) {
        this.queue.push(item);
        this.persistQueue();
    };
    OfflineActionsService.prototype.loadQueue = function () {
        var _this = this;
        // load from localstorage
        this.localStorage.getItem('queue').subscribe(function (queue) {
            _this.queue = queue || [];
            if (_this.isOnline) {
                _this.onConnectionRepair();
            }
        }, function () {
        });
    };
    OfflineActionsService.prototype.persistQueue = function () {
        // persist to localstorage
        this.localStorage.setItem('queue', this.queue).subscribe(function () {
        }, function () {
        });
    };
    OfflineActionsService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineActionsService_Factory() { return new OfflineActionsService(i0.inject(i1.LocalStorage), i0.inject(i2.ConnectionService), i0.inject(i3.ApiService)); }, token: OfflineActionsService, providedIn: "root" });
    return OfflineActionsService;
}());
export { OfflineActionsService };
