<ngx-loading-bar></ngx-loading-bar>
<mat-toolbar style="border-bottom: 1px solid #000">
    <mat-toolbar-row>
        <img alt="G-Style" src="/assets/images/logo.png" style="width: 150px"/>
        <span fxFlex></span>

       <app-notification-dropdown></app-notification-dropdown>

        <a mat-button routerLink="/bols">Bills of Lading</a>
        <a mat-button routerLink="/bols/archived">Archived</a>

        <button mat-button #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">
            <img alt="User avatar" *ngIf="user.avatar_url === null" class="user-avatar" src="/assets/images/default-avatar.png" />
            <img alt="User avatar" *ngIf="user.avatar_url !== null" class="user-avatar" [src]="user.avatar_url" />
            <span fxHide.lt-md>
                Welcome, {{ user.first_name }}
            </span>
            <i class="material-icons">
                keyboard_arrow_down
            </i>
        </button>

        <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
            <a [routerLink]="['/profile']" mat-menu-item>Profile</a>
            <a [routerLink]="['/auth/logout']" mat-menu-item>Logout</a>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
<app-connection-status></app-connection-status>

<div class="container mt-4">
    <router-outlet></router-outlet>
</div>