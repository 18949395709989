import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import {map, flatMap} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JobSafetyAnalysisService {

  constructor(protected apiService: ApiService) { 
  }

  signatureImages = [];

  //make methods for gathering data for JSA forms 


  saveOrUpdate(jsaData, id = null) : any {
    if(id == null){
      return this.apiService.post('jsas', jsaData);
    }
    return this.apiService.put(`jsas/${id}`, jsaData)
  }

  find(ids): Observable<any>{
    return this.apiService.get('jsas', {
      params: {
        ids: ids
      }
    });
  }

  findSignature(signatureType, jsaId){
    return this.apiService.get(`signatures/${signatureType}/${jsaId}`, {responseType: 'blob'} ).pipe(flatMap(response => {
      var blob = response as unknown as Blob;
      return this.readImageData(blob);
    }));
  }

  // This takes the blob and returns an observable converting it to base64 data
  readImageData(blob: Blob): Observable<any>{
    return Observable.create(observer => {
      var reader = new FileReader();
      reader.onloadend = function() {
          observer.next(reader.result); 
          observer.complete();             
      };
      reader.readAsDataURL(blob); 
    });
  }
}
