import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import { Observable, of } from 'rxjs';
import { OfflineActionsService } from './offline-actions.service';

export interface CreateLoadOptions {
    bol_id: number;
    status: string;
    number: number;
    billable_quantity: number;
    estimated_loads_remaining: number;
}

export interface LoadImage {
    name: string;
    source: string;
    image;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class LoadService {

    private uri = 'loads';

    constructor(private apiService: ApiService, private offlineActionService: OfflineActionsService) {
    // constructor(private http: HttpClient, private apiService: ApiService, private offlineActionService: OfflineActionsService) {
    }

    public create(loadOptions: CreateLoadOptions) {
        return this.apiService.post(this.uri, loadOptions);
    }

    public update(loadOptions: CreateLoadOptions, loadId) {
        return this.apiService.put(`${this.uri}/${loadId}`, loadOptions);
    }

    public finish(load, bolFinished = false) {
        const payload = {
            status: 'finished',
            bol_finished: bolFinished,
            billable_quantity: load.billable_quantity,
            estimated_loads_remaining: load.estimated_loads_remaining
        };

        return this.apiService.put(`${this.uri}/${load.id}`, payload);
    }

    public createLoadImage(loadId, image: LoadImage): Observable<any> {
        const uri = `load-images`;
        const payload = {
            load_id: loadId,
            url: image.url,
            name: image.name,
            image_data: image.source
        };
        return this.apiService.post(uri, payload);
    }

    public deleteLoadImage(imageId): Observable<any> {
        const uri = `load-images/${imageId}`;

        if ( ! window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'delete_load_image',
                data: {
                    id: imageId
                },
                method: 'delete',
                uri,
                payload: {},
                performed_at: new Date().toUTCString()
            });

            return of({
                data: {}
            });
        }

        return this.apiService.delete(uri);
    }
}
