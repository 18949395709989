import { ApiService } from "./api.service";
import { map, tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var CustomerService = /** @class */ (function () {
    function CustomerService(apiService) {
        this.apiService = apiService;
    }
    CustomerService.prototype.search = function (name) {
        if (name === void 0) { name = null; }
        if (name == null || name instanceof Object) {
            name = "";
        }
        return this.apiService.get('customers?search=' + name).pipe(tap(function (response) {
            response.results = response.data
                .map(function (customer) {
                return { id: customer.id, name: customer.name };
            });
            return response;
        }));
    };
    CustomerService.prototype.find = function (sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        return this.apiService.get('customers', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    };
    CustomerService.prototype.findOne = function (id) {
        return this.apiService.get('customers/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    CustomerService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('customers', payload);
        }
        return this.apiService.put('customers/' + id, payload);
    };
    CustomerService.prototype.destroy = function (id) {
        return this.apiService.delete('customers/' + id);
    };
    CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.inject(i1.ApiService)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
