/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bill-of-lading-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i3 from "@angular/material/radio";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./bill-of-lading/bill-of-lading.component.ngfactory";
import * as i8 from "./bill-of-lading/bill-of-lading.component";
import * as i9 from "../../common/bol.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "@angular/forms";
import * as i14 from "@angular/common";
import * as i15 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i16 from "ngx-loading";
import * as i17 from "./bill-of-lading-list.component";
var styles_BillOfLadingListComponent = [i0.styles];
var RenderType_BillOfLadingListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillOfLadingListComponent, data: {} });
export { RenderType_BillOfLadingListComponent as RenderType_BillOfLadingListComponent };
function View_BillOfLadingListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-radio-button", [["class", "status-option mat-radio-button"], ["color", "primary"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatRadioButton_0, i2.RenderType_MatRadioButton)), i1.ɵdid(1, 4440064, [[1, 4]], 0, i3.MatRadioButton, [[2, i3.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i5.UniqueSelectionDispatcher, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"], value: [1, "value"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_5 = "primary"; var currVal_6 = _v.context.$implicit; _ck(_v, 1, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).checked; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_3 = (0 - 1); var currVal_4 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = _v.context.$implicit; _ck(_v, 2, 0, currVal_7); }); }
function View_BillOfLadingListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-bill-of-lading", [], null, [[null, "decline"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("decline" === en)) {
        var pd_0 = (_co.onDecline(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ListBillOfLadingComponent_0, i7.RenderType_ListBillOfLadingComponent)), i1.ɵdid(1, 114688, null, 0, i8.ListBillOfLadingComponent, [i9.BolService, i10.MatDialog], { bol: [0, "bol"] }, { decline: "decline" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BillOfLadingListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Active Bills of Lading"]))], null, null); }
export function View_BillOfLadingListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i11.DefaultLayoutDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i11.DefaultFlexDirective, [i1.ElementRef, i12.StyleUtils, i12.LAYOUT_CONFIG, i11.FlexStyleBuilder, i12.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "mat-radio-group", [["class", "statuses mat-radio-group"], ["role", "radiogroup"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i13.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatRadioGroup]), i1.ɵdid(6, 1064960, null, 1, i3.MatRadioGroup, [i1.ChangeDetectorRef], null, { change: "change" }), i1.ɵqud(603979776, 1, { _radios: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingListComponent_1)), i1.ɵdid(9, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ngx-loading", [], null, null, null, i15.View_NgxLoadingComponent_0, i15.RenderType_NgxLoadingComponent)), i1.ɵdid(11, 114688, null, 0, i16.NgxLoadingComponent, [i16.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h1", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bills of Lading"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingListComponent_2)), i1.ɵdid(15, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillOfLadingListComponent_3)), i1.ɵdid(17, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "100"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.statuses; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.bols; _ck(_v, 15, 0, currVal_4); var currVal_5 = ((_co.bols.length === 0) && _co.bolsLoaded); _ck(_v, 17, 0, currVal_5); }, null); }
export function View_BillOfLadingListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bill-of-lading-list", [], null, null, null, View_BillOfLadingListComponent_0, RenderType_BillOfLadingListComponent)), i1.ɵdid(1, 114688, null, 0, i17.BillOfLadingListComponent, [i9.BolService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BillOfLadingListComponentNgFactory = i1.ɵccf("app-bill-of-lading-list", i17.BillOfLadingListComponent, View_BillOfLadingListComponent_Host_0, {}, {}, []);
export { BillOfLadingListComponentNgFactory as BillOfLadingListComponentNgFactory };
