import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

export interface CreateRouteOptions {
}

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    private uri = 'routes';

    constructor(private apiService: ApiService) {
    }

    public create(routeOptions: CreateRouteOptions) {
        return this.apiService.post(this.uri, routeOptions);
    }

    public createLoadRoute(id: number, routeOptions) {
        return this.apiService.post(`loads/${id}/route`, routeOptions);
    }

    public update(routeOptions) {
        return this.apiService.put(`${this.uri}/${routeOptions.id}`, routeOptions);
    }

}
