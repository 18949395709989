import {Injectable} from '@angular/core';
import Echo from "laravel-echo";
import * as Pusher from 'pusher-js';
import {NotificationService} from "./notification.service";
import {AuthenticationService} from "../authentication/authentication.service";
import {ApiService} from "./api.service";
import {environment} from "../../environments/environment";
import { interval } from "rxjs";
import { take } from "rxjs/operators";

declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    private echo;

    retryTimer = null;
    private user;

    constructor(private notificationService: NotificationService,
                private apiService: ApiService,
                private authService: AuthenticationService) {
    }

    initializeConnection() {

        const wsHost = environment.websocket_host;
        const wsPort = environment.websocket_port;
        const authEndpoint = `${environment.api_url}/broadcasting/auth`;
        const pusherKey = environment.pusher_key;

        let config: any = {
            wsHost: wsHost,
            authEndpoint: authEndpoint,
            wsPort: wsPort,
            disableStats: true,
            enabledTransports: ['ws'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.authService.token
                },
            },
        };

        let client = new Pusher(pusherKey, config);

        client.connection.bind('state_change', ((states) => {
            this.processStateChange(states.previous, states.current);
        }));

        this.echo = new Echo({
            broadcaster: 'pusher',
            disableStats: true,
            authEndpoint: authEndpoint,
            namespace: 'GStyle',
            client: client
        });


        this.apiService.get('me').subscribe(response => {
            this.user = response;
            this.subscribeToNotifications();
        });

    }

    processStateChange(previous, current) {
        if(previous === 'unavailable' && current === 'connected') {
            this.notificationService.fetchUnread().pipe(take(1)).subscribe();
        }
    }

    subscribeToNotifications() {
        this.echo.private(`users.${this.user.id}`)
            .notification((notification) => {
                this.notificationService.addUnreadNotification(notification, false, true);
            });
    }

    setupRetry() {
        this.retryTimer = setInterval(() => {
            console.log('Retry connection');
            this.echo.connect();
        }, 120 * 1000);
    }
}
