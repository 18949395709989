import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var RouteService = /** @class */ (function () {
    function RouteService(apiService) {
        this.apiService = apiService;
        this.uri = 'routes';
    }
    RouteService.prototype.create = function (routeOptions) {
        return this.apiService.post(this.uri, routeOptions);
    };
    RouteService.prototype.createLoadRoute = function (id, routeOptions) {
        return this.apiService.post("loads/" + id + "/route", routeOptions);
    };
    RouteService.prototype.update = function (routeOptions) {
        return this.apiService.put(this.uri + "/" + routeOptions.id, routeOptions);
    };
    RouteService.ngInjectableDef = i0.defineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.inject(i1.ApiService)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
