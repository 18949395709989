import {NgModule} from '@angular/core';
import {CommonModule as AngularCommon} from '@angular/common';
import {AuthenticationGuard} from "../authentication/authentication.guard";
import {AuthenticationService} from "../authentication/authentication.service";
import {ApiService} from "./api.service";
import {BolService} from "./bol.service";
import {LoadService} from "./load.service";
import {SocketService} from "./socket.service";
import {NotificationService} from "./notification.service";
import {SessionService} from "./session.service";
import {UserResolver} from "./user.resolver";
import { AskToUpdateComponent } from './ask-to-update/ask-to-update.component';
import {PwaService} from "./pwa.service";
import {MatButtonModule, MatDialogModule} from "@angular/material";
import { OfflineDisableDirective } from './offline-disable.directive';

@NgModule({
    declarations: [AskToUpdateComponent, OfflineDisableDirective],
    exports: [AskToUpdateComponent, OfflineDisableDirective],
    entryComponents: [AskToUpdateComponent],
    imports: [
        AngularCommon,
        MatDialogModule,
        MatButtonModule
    ],
    providers: [
        AuthenticationGuard,
        AuthenticationService,
        ApiService,
        BolService,
        LoadService,
        SocketService,
        SessionService,
        UserResolver,
        PwaService,
        NotificationService
    ]
})
export class CommonModule {
}
