import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { OfflineActionsService } from './offline-actions.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { tap } from 'rxjs/internal/operators/tap';
import { SessionService } from './session.service';
import { MatSnackBar } from "@angular/material";
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@ngx-pwa/local-storage";
import * as i3 from "./session.service";
import * as i4 from "./offline-actions.service";
import * as i5 from "@angular/material/snack-bar";
var BolService = /** @class */ (function () {
    function BolService(apiService, localStorage, sessionService, offlineActionService, snackBar) {
        this.apiService = apiService;
        this.localStorage = localStorage;
        this.sessionService = sessionService;
        this.offlineActionService = offlineActionService;
        this.snackBar = snackBar;
        this.uri = 'bols';
    }
    Object.defineProperty(BolService.prototype, "bols", {
        get: function () {
            var uri = "me/" + this.uri;
            return this.apiService.get(uri);
        },
        enumerable: true,
        configurable: true
    });
    BolService.prototype.getBols = function (filters) {
        if (filters === void 0) { filters = null; }
        var uri = "me/" + this.uri;
        return this.apiService.get(uri, { params: filters });
    };
    BolService.prototype.getArchivedBols = function (page) {
        var uri = "me/" + this.uri + "?status=archived&page=" + page;
        return this.apiService.get(uri);
    };
    BolService.prototype.syncLoads = function (id, bol) {
        var _this = this;
        var uri = this.uri + "/" + id + "/loads";
        if (!bol.loads) {
            return;
        }
        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'sync_bol_loads',
                data: bol,
                method: 'post',
                uri: uri,
                payload: bol,
                performed_at: new Date().toUTCString()
            });
            this.updateCached(bol, uri);
            return of({
                data: bol
            });
        }
        return this.apiService.post(uri, bol).pipe(tap(function (response) {
            _this.updateCached(response.data, uri, false);
        }));
    };
    BolService.prototype.accept = function (bol) {
        var _this = this;
        var payload = {
            status: 'accepted'
        };
        var uri = this.uri + "/" + bol.id;
        var accept$ = this.apiService.put(this.uri + "/" + bol.id, payload).pipe(catchError(function (error) {
            if (error.status === 404) {
                _this.snackBar.open('Looks like this BOL no longer exists, please refresh or start another BOL', 'Close', { duration: 5000 });
            }
            return of({ error: true });
        }), tap(function (response) {
            if (!response['error']) {
                bol.status = 'accepted';
                _this.updateCached(bol, uri);
            }
        }));
        return accept$;
    };
    BolService.prototype.updateVehicles = function (bol) {
        var payload = {
            truck: bol.truck.id,
            trailer: bol.trailer.id
        };
        var uri = this.uri + "/" + bol.id;
        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'update_vehicles',
                data: bol,
                method: 'put',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });
            this.updateCached(bol, uri);
            return of({
                data: {
                    truck: bol.truck,
                    trailer: bol.trailer
                }
            });
        }
        return this.apiService.put(uri, payload);
    };
    BolService.prototype.start = function (bol) {
        var payload = {
            status: 'started'
        };
        var uri = this.uri + "/" + bol.id;
        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'start_bol',
                data: bol,
                method: 'put',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });
            bol.status = 'started';
            bol.loads = [];
            this.updateCached(bol, uri);
            return of({
                data: {
                    status: 'started',
                    loads: []
                }
            });
        }
        return this.apiService.put(uri, payload);
    };
    BolService.prototype.finish = function (bol) {
        var payload = {
            status: 'finished'
        };
        return this.apiService.put(this.uri + "/" + bol.id, payload);
    };
    BolService.prototype.decline = function (bol) {
        var payload = {
            status: 'rejected'
        };
        return this.apiService.put(this.uri + "/" + bol.id, payload);
    };
    BolService.prototype.getLinks = function () {
        return this.apiService.get("me/bol-links");
    };
    BolService.prototype.get = function (id) {
        var _this = this;
        var uri = this.uri + "/" + id;
        if (!window.navigator.onLine) {
            // get cached
            return this.localStorage.getItem(uri);
        }
        return this.apiService.get(uri, {
            params: {
                include: 'route,truck,trailer,loads,loads.route,loads.images,job,job.well,job.billingType,job.billingTypeOptional,job.well.rig,job.material,job.dispatchers'
            }
        }).pipe(tap(function (response) {
            _this.localStorage.setItem(uri, response).subscribe(function (response) {
            }, function () {
            });
        }));
    };
    BolService.prototype.updateJsaId = function (data) {
        var payload = data;
        var uri = this.uri + "/" + data.bol.id;
        this.apiService.put(uri, payload).subscribe();
    };
    BolService.prototype.getComments = function (id) {
        var _this = this;
        var uri = "bols/" + id + "/comments";
        if (!window.navigator.onLine) {
            // get cached
            return this.localStorage.getItem(uri);
        }
        return this.apiService.get(uri).pipe(tap(function (response) {
            _this.localStorage.setItem(uri, response).subscribe(function (response) {
            }, function () {
            });
        }));
    };
    BolService.prototype.saveComment = function (id, payload) {
        var uri = "bols/" + id + "/comments";
        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'save_comment',
                data: payload,
                method: 'post',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });
            var user = this.sessionService.user;
            payload.avatar_url = user.avatar_url;
            payload.user = user;
            payload.created_at_formatted = moment().format('M/D h:mm A');
            this.updateCachedComments(payload, uri);
            return of({
                data: payload
            });
        }
        return this.apiService.post(uri, payload);
    };
    BolService.prototype.updateCached = function (bol, key, merge) {
        var _this = this;
        if (merge === void 0) { merge = true; }
        this.localStorage.getItem(key).subscribe(function (response) {
            var cached = response;
            if (cached === null) {
                cached = bol;
            }
            else {
                if (merge) {
                    cached.data = tslib_1.__assign({}, cached.data, bol);
                }
                else {
                    cached.data = bol;
                }
            }
            _this.localStorage.setItem(key, cached).subscribe(function (response) {
            }, function () {
            });
        }, function () {
        });
    };
    BolService.prototype.updateCachedComments = function (comment, key) {
        var _this = this;
        this.localStorage.getItem(key).subscribe(function (response) {
            var cached = response;
            if (cached === null) {
                cached = [comment];
            }
            else {
                cached.data.push(comment);
            }
            _this.localStorage.setItem(key, cached).subscribe(function (response) {
            }, function () {
            });
        }, function () {
        });
    };
    BolService.prototype.createFormData = function (object, form, namespace) {
        var formData = form || new FormData();
        for (var property in object) {
            if (!object.hasOwnProperty(property) || !object[property]) {
                continue;
            }
            var formKey = namespace ? namespace + "[" + property + "]" : property;
            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            }
            else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
                this.createFormData(object[property], formData, formKey);
            }
            else {
                formData.append(formKey, object[property]);
            }
        }
        return formData;
    };
    BolService.ngInjectableDef = i0.defineInjectable({ factory: function BolService_Factory() { return new BolService(i0.inject(i1.ApiService), i0.inject(i2.LocalStorage), i0.inject(i3.SessionService), i0.inject(i4.OfflineActionsService), i0.inject(i5.MatSnackBar)); }, token: BolService, providedIn: "root" });
    return BolService;
}());
export { BolService };
