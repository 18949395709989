import { FormControl } from '@angular/forms';

export function MySQLIntValidator(): any {
    return (control: FormControl): { [key: string]: any } => {

        if (control.value === null || control.value === '') {
            return null;
        }

        // Check if it is an integer
        if (parseFloat(control.value) !== parseInt(control.value, 10) || isNaN(control.value)) {
            return { invalidInt: true };
        }

        // check if it is in range
        if (control.value > 2147483647 || control.value < -2147483648) {
            return { outOfRange: true };
        }

        // valid int
        return null;
    };
}
