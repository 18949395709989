<div class="comment-container" fxLayout="row wrap" style="padding: 5px;" *ngIf="enabled">
    <div class="comment" *ngFor="let comment of comments" fxFlex="100" fxLayout="row" style="margin-bottom: 10px;">
        <div class="comment-avatar" fxFlex="20">
            <img *ngIf="comment.user.avatar_url === null" src="/assets/images/default-avatar.png"
                 style="width: 45px; border-radius: 50%; display: block; margin: auto; margin-top: 20px"/>

            <img *ngIf="comment.user.avatar_url !== null" [src]="comment.user.avatar_url"
                 style="width: 45px; border-radius: 50%; display: block; margin: auto; margin-top: 20px"/>
        </div>
        <div class="comment-text" fxFlex="80">
            <p class="comment-heading" style="font-size: 0.8em">
                {{ comment.user.first_name}} {{ comment.user.last_name }} @ {{ comment.created_at_formatted }}
            </p>
            <p class="comment-body">
                {{ comment.body }}
            </p>
        </div>
    </div>
</div>

<div class="comment-form" style="padding: 20px; position: relative" *ngIf="enabled">
    <ngx-loading [show]="loading"></ngx-loading>
    <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="row wrap">
        <mat-form-field fxFlex="100" *ngIf="!archived">
            <textarea matInput placeholder="Message" formControlName="body" [rows]="4"></textarea>
        </mat-form-field>
        <mat-error fxFlex="100" *ngIf="form.get('body').hasError('invalidWhitespace') && submitted">
            {{ form.get('body').getError('invalidWhitespace') }}
        </mat-error>

        <div fxFlex="20">
            <button (click)="onClose()" mat-button >Close</button>
        </div>
        <div fxFlex fxLayoutAlign="end">
            <button *ngIf="!archived" type="submit" mat-flat-button color="primary">Save</button>
        </div>
    </form>
</div>

<div *ngIf=" ! enabled" style="padding: 20px; text-align: center; margin: auto">
    Save BOL to add comments.
</div>
