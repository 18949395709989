import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-ask-to-update',
  templateUrl: './ask-to-update.component.html',
  styleUrls: ['./ask-to-update.component.scss']
})
export class AskToUpdateComponent {

  constructor(public dialogRef: MatDialogRef<AskToUpdateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onClose(): void {
    this.dialogRef.close( {});
  }

}
