<h1 mat-dialog-title>Load Locations</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <ngx-loading [show]="loading"></ngx-loading>

        <div fxLayout="row wrap" fxLayoutAlign="none" fxFlexAlign="none">
            <div fxFlex="45" class="location-form-column" fxLayout="row wrap" fxLayoutAlign="start start">
                <h2>Pickup</h2>
                <mat-form-field fxFlex="100">
                    <mat-select placeholder="Pickup Type" formControlName="pickup_type">
                        <mat-optgroup [label]="'Manual'">
                            <mat-option value="gps">GPS</mat-option>
                            <mat-option value="address">Address</mat-option>
                        </mat-optgroup>
                        <mat-optgroup [label]="'Auto'">
                            <!-- make the group disabled online if you cannot find a way to make it functional offline -->
                        <!-- <mat-optgroup [label]="'Auto'" [disabled]="!online"> -->
                            <mat-option value="well">Well</mat-option>
                            <mat-option *ngFor="let type of types" [value]="type">{{ type.name }}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="locationFields.pickup_well">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100">
                        <input name="route_pickup_well" matInput placeholder="Pickup Well" [matAutocomplete]="auto"
                               formControlName="pickup_well">
                    </mat-form-field>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="wellDisplayFn">
                        <mat-option *ngIf="areWellsLoading" class="is-loading">
                            <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!areWellsLoading">
                            <mat-option *ngFor="let well of filteredWells" [value]="well">
                                <span>{{ well.name }}</span>
                                <small> | ID: {{well.id}}</small>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </ng-container>

                <ng-container *ngIf="locationFields.pickup_address">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100" style="position: relative">
                        <mat-label>Pickup Address</mat-label>
                        <input matInput
                               class="pickup_address"
                               style="position: relative"
                               #pickupAddressInput
                               matGoogleMapsAutocomplete
                               [country]="'us'"
                               [types]="['address']"
                               (onAutocompleteSelected)="onPickupAutocompleteSelected($event)"
                               (onLocationSelected)="onPickupLocationSelected($event)">
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="locationFields.pickup_location">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100">
                        <input name="route_pickup_dynamic_location" matInput [placeholder]="'Pickup ' + form.get('pickup_type').value.name" [matAutocomplete]="pickupLocationAuto"
                               formControlName="pickup_dynamic_location">
                    </mat-form-field>

                    <mat-autocomplete #pickupLocationAuto="matAutocomplete" [displayWith]="dynamicLocationDisplayFn">
                        <mat-option *ngIf="arePickupLocationsLoading" class="is-loading">
                            <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!arePickupLocationsLoading">
                            <mat-option *ngFor="let location of filteredPickupLocations" [value]="location">
                                <span>{{ location.name }}</span>
                                <small> | ID: {{location.id}}</small>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </ng-container>


                <mat-form-field fxFlex="100">
                    <input matInput placeholder="Pickup Name" name="pickup_name" formControlName="pickup_name">
                    <mat-error *ngIf="form.get('pickup_name').hasError('required') && submitted">
                        Pickup name is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="100">
                    <input matInput placeholder="Pickup Location" name="pickup_location"
                           formControlName="pickup_location">
                    <mat-error *ngIf="form.get('pickup_location').hasError('required') && submitted">
                        Pickup location is required.
                    </mat-error>
                    <mat-error *ngIf="form.get('pickup_location').errors &&
                    form.get('pickup_location').dirty &&
                    form.get('pickup_location').errors.validCoordinates">
                        Field must contain valid GPS coordinates.
                    </mat-error>
                    <mat-hint>GPS Coordinates</mat-hint>
                </mat-form-field>

            </div>
            <div fxFlex="45" class="location-form-column" fxLayout="row wrap" fxLayoutAlign="start start">
                <h2>Dropoff</h2>
                <mat-form-field fxFlex="100">
                    <mat-select placeholder="Dropoff Type" formControlName="dropoff_type">
                        <mat-optgroup [label]="'Manual'">
                            <mat-option value="gps">GPS</mat-option>
                            <mat-option value="address">Address</mat-option>
                        </mat-optgroup>
                        <mat-optgroup [label]="'Auto'">
                            <mat-option value="well">Well</mat-option>
                            <mat-option *ngFor="let type of types" [value]="type">{{ type.name }}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="locationFields.dropoff_well">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100">
                        <input name="route_dropoff_well" matInput placeholder="Dropoff Well" [matAutocomplete]="auto"
                               formControlName="dropoff_well">
                    </mat-form-field>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="wellDisplayFn">
                        <mat-option *ngIf="areWellsLoading" class="is-loading">
                            <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!areWellsLoading">
                            <mat-option *ngFor="let well of filteredWells" [value]="well">
                                <span>{{ well.name }}</span>
                                <small> | ID: {{well.id}}</small>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </ng-container>

                <ng-container *ngIf="locationFields.dropoff_address">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100">
                        <mat-label>Dropoff Address</mat-label>
                        <input matInput
                               #dropoffAddressInput
                               matGoogleMapsAutocomplete
                               [country]="'us'"
                               [types]="['address']"
                               (onAutocompleteSelected)="onDropoffAutocompleteSelected($event)"
                               (onLocationSelected)="onDropoffLocationSelected($event)">
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="locationFields.dropoff_location">
                    <mat-form-field [floatLabel]="'auto'" fxFlex="100">
                        <input name="route_dropoff_dynamic_location" matInput [placeholder]="'Dropoff ' + form.get('dropoff_type').value.name" [matAutocomplete]="dropoffLocationAuto"
                               formControlName="dropoff_dynamic_location">
                    </mat-form-field>

                    <mat-autocomplete #dropoffLocationAuto="matAutocomplete" [displayWith]="dynamicLocationDisplayFn">
                        <mat-option *ngIf="areDropoffLocationsLoading" class="is-loading">
                            <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!areDropoffLocationsLoading">
                            <mat-option *ngFor="let location of filteredDropoffLocations" [value]="location">
                                <span>{{ location.name }}</span>
                                <small> | ID: {{location.id}}</small>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </ng-container>

                <mat-form-field fxFlex="100">
                    <input matInput placeholder="Dropoff Name" name="dropoff_name" formControlName="dropoff_name">
                    <mat-error *ngIf="form.get('dropoff_name').hasError('required') && submitted">
                        Dropoff name is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="100">
                    <input matInput placeholder="Dropoff Location" name="dropoff_location"
                           formControlName="dropoff_location">
                    <mat-error *ngIf="form.get('dropoff_location').hasError('required') && submitted">
                        Dropoff location is required.
                    </mat-error>
                    <mat-error *ngIf="form.get('dropoff_location').errors &&
                    form.get('dropoff_location').dirty &&
                    form.get('dropoff_location').errors.validCoordinates">
                        Field must contain valid GPS coordinates.
                    </mat-error>
                    <mat-hint>GPS Coordinates</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="dialog-actions" fxLayoutAlign="end end">
        <button mat-flat-button type="button" (click)="onNoClick()" >Cancel</button>
        <button mat-flat-button color="primary" type="submit">Submit</button>
    </div>
</form>
