import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, tap } from 'rxjs/operators';

export interface Well {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class WellService {
    constructor(protected apiService: ApiService) {
    }

    public findByRig(rigId) {
        return this.apiService.get(`rigs/${rigId}/wells`);
    }

    public findByCustomer(customerId) {
        return this.apiService.get(`wells`, {
            params: {
                customer: customerId
            }
        });
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10, filters = null, mode = 'paged') {

        let options = {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString(),
                search: '',
                mode
            }
        };

        if (filters !== null) {
            if (filters.status) {
                options.params['status'] = filters.status;
            }

            if (filters.search !== '') {
                options.params.search = filters.search;
            }
        }

        return this.apiService.get('wells', options);
    }

    public findOne(id) {
        return this.apiService.get('wells/' + id).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('wells', payload)
        }

        return this.apiService.put('wells/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('wells/' + id);
    }

    public searchWells(name = null) {
        if (name == null || name instanceof Object) {
            name = '';
        }

        return this.apiService.get('wells?search=' + name).pipe(
            tap((response: any) => {
                response.results = response.data
                    .map(well => {
                        return {id: well.id, name: well.name, location: well.location}
                    });

                return response;
            })
        );
    }

    public search(name = null) {

        if (name == null || name instanceof Object) {
            name = '';
        }

        return this.apiService.get('rigs?search=' + name).pipe(
            tap((response: any) => {
                response.results = response.data
                    .map(rig => {
                        return {id: rig.id, name: rig.name, customer: rig.customer}
                    });

                return response;
            })
        );

    }
}
