import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'app-vehicle-modal',
    templateUrl: './vehicle-modal.component.html',
    styleUrls: ['./vehicle-modal.component.scss']
})
export class VehicleModalComponent {

    public truck;
    public trailer;
    public bol;
    public showTruckChange = false;
    public showTrailerChange = false;

    constructor(public dialogRef: MatDialogRef<VehicleModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.truck = data.truck;
        this.trailer = data.trailer;
        this.bol = data.bol;
    }

    onClose(): void {
        this.dialogRef.close({});
    }

    changeTruck() {
        this.showTruckChange = true;
    }

    changeTrailer() {
        this.showTrailerChange = true;
    }

    onTrailerChange(trailer) {
        this.showTrailerChange = false;
        this.trailer = trailer;
        this.dialogRef.close({
            status: 'success',
            type: 'trailer',
            trailer: trailer
        });
    }

    onTruckChange(truck) {
        this.showTruckChange = false;
        this.truck = truck;
        this.dialogRef.close({
            status: 'success',
            type: 'truck',
            truck: truck
        });
    }


}
