import { ApiService } from "./api.service";
import { flatMap } from "rxjs/operators";
import { Observable } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var JobSafetyAnalysisService = /** @class */ (function () {
    function JobSafetyAnalysisService(apiService) {
        this.apiService = apiService;
        this.signatureImages = [];
    }
    //make methods for gathering data for JSA forms 
    JobSafetyAnalysisService.prototype.saveOrUpdate = function (jsaData, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('jsas', jsaData);
        }
        return this.apiService.put("jsas/" + id, jsaData);
    };
    JobSafetyAnalysisService.prototype.find = function (ids) {
        return this.apiService.get('jsas', {
            params: {
                ids: ids
            }
        });
    };
    JobSafetyAnalysisService.prototype.findSignature = function (signatureType, jsaId) {
        var _this = this;
        return this.apiService.get("signatures/" + signatureType + "/" + jsaId, { responseType: 'blob' }).pipe(flatMap(function (response) {
            var blob = response;
            return _this.readImageData(blob);
        }));
    };
    // This takes the blob and returns an observable converting it to base64 data
    JobSafetyAnalysisService.prototype.readImageData = function (blob) {
        return Observable.create(function (observer) {
            var reader = new FileReader();
            reader.onloadend = function () {
                observer.next(reader.result);
                observer.complete();
            };
            reader.readAsDataURL(blob);
        });
    };
    JobSafetyAnalysisService.ngInjectableDef = i0.defineInjectable({ factory: function JobSafetyAnalysisService_Factory() { return new JobSafetyAnalysisService(i0.inject(i1.ApiService)); }, token: JobSafetyAnalysisService, providedIn: "root" });
    return JobSafetyAnalysisService;
}());
export { JobSafetyAnalysisService };
