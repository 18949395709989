import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

export interface Customer {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    constructor(protected apiService: ApiService) {
    }

    public search(name = null) {

        if(name == null || name instanceof Object) {
            name = ""
        }

        return this.apiService.get('customers?search=' + name).pipe(
            tap((response: any) => {
                response.results = response.data
                    .map(customer => {
                        return { id: customer.id, name: customer.name }
                    });

                return response;
            })
        )

    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10) {

        return this.apiService.get('customers', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('customers/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('customers', payload)
        }

        return this.apiService.put('customers/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('customers/' + id);
    }
}
