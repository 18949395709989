import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BolCommentService} from "../../../common/bol-comment.service";
import { MatSnackBar } from "@angular/material";
import { BolService } from "../../../common/bol.service";
import { finalize } from 'rxjs/operators';
import { WhitespaceValidator } from 'src/app/common/validators';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

    @Input() bol;
    @Input() archived;
    @Output() close = new EventEmitter();

    form: FormGroup;
    public submitted = false;
    public loading = false;

    constructor(private formBuilder: FormBuilder,
                private notify: MatSnackBar,
                private bolCommentService: BolCommentService,
                private bolService: BolService) {
    }

    public enabled = false;
    public comments = [];

    ngOnInit() {
        this.form = this.formBuilder.group({
            body: ['', [Validators.required, WhitespaceValidator()]]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bol && changes.bol.currentValue && changes.bol.currentValue.id) {
            this.enabled = true;
            this.getComments();
        }
    }

    private getComments() {
        this.loading = true;
        this.bolService.getComments(this.bol.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.comments = response.data;
        })
    }

    private submit() {

        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const payload = this.form.value;

        this.loading = true;
        this.bolService.saveComment(this.bol.id, payload).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.comments.push(response.data);
            this.notify.open('Comment saved successfully', 'Close', {
                duration: 3000
            });
            this.close.emit(true);
        })
    }

    onClose() {
        this.close.emit(true);
    }
}
