import { FormControl } from "@angular/forms";

export function WhitespaceValidator(): any {
    return (control: FormControl): { [key: string]: any } => {
        let errors = {};
        if ((control.value || '').replace(/\s+/g, '') === '') {
            errors['invalidWhitespace'] = 'This field cannot be empty';
            return errors;
        }
        return null;
    }
}