export const environment = {
  production: true,
  api_url: ENV.API_URL || 'https://api.gstyletransport.com',
  login_url: ENV.LOGIN_URL || 'https://login.gstyletransport.com',
  api_client_id: ENV.API_CLIENT_ID || 4,
  use_service_workers: ENV.USE_SERVICE_WORKERS || true,
  websocket_host: ENV.WEBSOCKET_HOST || 'ws.gstyletransport.com',
  websocket_port: ENV.WEBSOCKET_PORT || 6001,
  pusher_key: ENV.PUSHER_KEY || '2X1aQxxmedPCELYVDO7Q84o51VrvOT42',
  vapid_key: ENV.VAPID_KEY || 'BCzR4xdtpittTXfqc3Vk0wbsyF2YABPy3CyiHoncc39AQnu4lg4IwPZQVuh9QfecSlLtmkS99/UG1rWcIY970r8=',
  google_maps_key: ENV.GOOGLE_MAPS_KEY || 'AIzaSyDJCTPw8ifuaoEucW9lEd-f483hiN0szpw',
  sentry_dsn: ENV.SENTRY_PUBLIC_DSN || ''
};
