import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _token = null;

    private _user = null;

    get user() {
        return this._user;
    }

    set user(user) {
        this._user = user;
    }

    get token() {
        if(this._token !== null) {
            return this._token;
        }

        this.setTokenFromLocalStorage();

        return this._token;
    }

    set token(token) {
        this._token = token;
    }

    constructor() {}

    public setTokenFromLocalStorage()
    {
        this.token = localStorage.getItem('gstyle.token');
    }
}
