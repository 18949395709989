<div fxLayout="row">
    <div fxFlex="100">
        <mat-radio-group class="statuses" (change)="handleStatusChange($event)">
            <mat-radio-button color="primary" class="status-option" *ngFor="let status of statuses" [value]="status">
                {{status}}
            </mat-radio-button>
        </mat-radio-group>
        <ngx-loading [show]="loading"></ngx-loading>
        <h1 class="page-header">Bills of Lading</h1>
        <app-list-bill-of-lading *ngFor="let bol of bols" [bol]="bol" (decline)="onDecline(bol)"></app-list-bill-of-lading>

        <ng-container *ngIf="bols.length === 0 && bolsLoaded">
            <h2 class="page-header">No Active Bills of Lading</h2>
        </ng-container>
    </div>
</div>

