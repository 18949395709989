import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {

    public isOnline: boolean;
    public onlineEvent;
    public offlineEvent;
    private subscriptions: Subscription[] = [];

    public connectionChange = new BehaviorSubject(true);

    constructor() {
        // subscribe to connection status
        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        this.isOnline = navigator.onLine;

        if( ! this.isOnline) {
            this.emitConnectionChange(false);
        }

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
            this.isOnline = true;
            this.emitConnectionChange(true);
        }));

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            this.isOnline = false;
            this.emitConnectionChange(false);
        }));
    }

    emitConnectionChange(online) {
        this.connectionChange.next(online);
    }

    // call on driver module destroy
    @HostListener("window:beforeunload", ["$event"])
    onDestroy(event) {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
