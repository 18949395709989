<mat-tab-group [color]="'warn'" (selectedTabChange)="selectedBolChanged($event)" [selectedIndex]="selectedIndex" *ngIf=" ! archived">
    <mat-tab *ngFor="let link of links" label="Job {{ link.job_number}} - BOL {{ link.number }}">
        <ng-template matTabContent>
            <app-bill-of-lading [bolId]="link.id" (reject)="onBolReject($event)"></app-bill-of-lading>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<ng-container *ngIf="archived">
    <app-bill-of-lading [bolId]="id" [archived]="true"></app-bill-of-lading>
</ng-container>