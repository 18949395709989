import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {MatDialog} from "@angular/material";
import {AskToUpdateComponent} from "./ask-to-update/ask-to-update.component";

@Injectable()
export class PwaService {

    public promptEvent = null;

    constructor(private swUpdate: SwUpdate, private dialog: MatDialog) {

        // prompt for install
        window.addEventListener('beforeinstallprompt', event => {
            this.promptEvent = event;
        });

        swUpdate.available.subscribe(event => {
            const dialogRef = this.dialog.open(AskToUpdateComponent, {
                width: '600px',
                data: {}
            });

            dialogRef.afterClosed().subscribe(result => {
                window.location.reload();
            });
        });

    }
}