import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly baseUrl;

    constructor(protected http: HttpClient) {
        this.baseUrl = environment.api_url;
    }

    public get<T>(uri, options: any = {}) {
        const url = this.getUrl(uri);

        let params = new HttpParams();

        for (let key in options.params) {
            params = params.set(key, options.params[key])
        }

        options.params = params;

        return this.http.get<T>(url, options);
    }

    public post(uri, payload = {}, options: any = {}) {
        const url = this.getUrl(uri);

        return this.http.post(url, payload, options);
    }

    public put(uri, payload = {}, options: any = {}) {
        const url = this.getUrl(uri);

        return this.http.put(url, payload, options);
    }

    public delete(uri, options: any = {}) {
        const url = this.getUrl(uri);

        return this.http.delete(url, options);
    }

    private getUrl(uri) {
        return `${this.baseUrl}/${uri}`;
    }
}
