import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var VisibilityService = /** @class */ (function () {
    function VisibilityService() {
        var _this = this;
        this.visibilityChange = new BehaviorSubject(true);
        // Handle page visibility change
        document.addEventListener('visibilitychange', function () {
            if (document['hidden']) {
                _this.visibilityChange.next(false);
            }
            else {
                _this.visibilityChange.next(true);
            }
        }, false);
    }
    VisibilityService.ngInjectableDef = i0.defineInjectable({ factory: function VisibilityService_Factory() { return new VisibilityService(); }, token: VisibilityService, providedIn: "root" });
    return VisibilityService;
}());
export { VisibilityService };
