import { ApiService } from './api.service';
import { tap } from 'rxjs/internal/operators/tap';
import * as Sentry from '@sentry/browser';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var SessionService = /** @class */ (function () {
    function SessionService(apiService) {
        this.apiService = apiService;
        this._user = null;
    }
    Object.defineProperty(SessionService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (user) {
            this._user = user;
            // Add a little extra sentry context
            Sentry.configureScope(function (scope) {
                scope.setUser({
                    id: user.id,
                    role: user.role,
                    title: user.title,
                    email: user.email,
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.fetch = function () {
        var _this = this;
        var user$ = this.apiService.get('me');
        return user$.pipe(tap((function (response) {
            _this.user = response;
        })));
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.inject(i1.ApiService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
