import { Component, Input, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BolService} from "../../common/bol.service";
import {MatSnackBar} from "@angular/material";
import {NotificationService} from "../../common/notification.service";

@Component({
    selector: 'app-bill-of-lading-detail',
    templateUrl: './bill-of-lading-detail.component.html',
    styleUrls: ['./bill-of-lading-detail.component.scss']
})
export class BillOfLadingDetailComponent implements OnInit {

    public id = null;
    public oldId = null;
    public links = null;
    public selectedIndex = null;
    public pastFirstLoad = false;

    public archived = false;

    // hack to differentiate between tab click and setTab()
    public programmaticallyChanged = false;

    constructor(private bolService: BolService,
                private router: Router,
                private snackbar: MatSnackBar,
                private notificationService: NotificationService,
                private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.id = Number(this.route.snapshot.paramMap.get('id'));

        if(this.route.snapshot.data.archived && this.route.snapshot.data.archived === true) {
            this.archived = true;
        }

        this.init();

        this.route.params.subscribe(routeParams => {
            if( ! this.pastFirstLoad) {
                this.pastFirstLoad = true;
                return;
            }

            this.oldId = this.id;
            this.id = Number(routeParams.id);

            this.init();
        });
    }



    init() {

        if(this.links == null) {
            this.bolService.getLinks().subscribe((response: any) => {
                this.links = response.data;
                this.setTab(this.id, response.data);
            });
        } else {
            this.setTab(this.id, this.links);
        }

    }

    setTab(id, links) {
        let found = false;
        for(let ii = 0; ii < links.length; ++ii) {


            if(links[ii].id === id) {
                // hack to differentiate between tab click and setTab()
                this.programmaticallyChanged = true;
                this.selectedIndex = ii;
                found = true;
            }
        }

        if( ! found && this.oldId !== null) {
            this.snackbar.open('Cannot find BOL', 'Close', {
                duration: 5000
            });
            this.router.navigate(['../', this.oldId], { relativeTo: this.route });
        }
    }

    selectedBolChanged(event) {
        const id = this.links[event.index].id;

        // hack to differentiate between tab click and setTab()
        if(this.programmaticallyChanged) {
            this.programmaticallyChanged = false;
            return;
        }

        this.router.navigate(['../', id], { relativeTo: this.route });
    }

    onBolReject(bol) {
        for(let ii = 0; ii < this.links.length; ++ii) {
            if(this.links[ii].id == bol.id) {
                this.links.splice(ii, 1);
                this.selectedIndex = 0;
            }
        }
    }

}
