import {Directive, ElementRef, OnInit} from '@angular/core';
import {ConnectionService} from "./connection.service";

@Directive({
    selector: '[offline-disable]'
})
export class OfflineDisableDirective implements OnInit {

    private element: ElementRef;

    constructor(private elRef: ElementRef,
                private connectionService: ConnectionService) {

        this.element = elRef;
    }

    ngOnInit(): void {
        this.connectionService.connectionChange.subscribe(status => {
            this.element.nativeElement.disabled = status === false;
        });
    }


}
