import { FormControl } from '@angular/forms';

export function MySQLDecimalValidator(digits, precision): any {
    return (control: FormControl): { [key: string]: any } => {
        // allow empty fields
        if (control.value === null || control.value === '') {
            return null;
        }

        // check if it is a number
        if (isNaN(control.value)) {
            return { invalidNum: true };
        }

        const between = Math.pow(10, digits - precision) - (1 / Math.pow(10, precision));

        if (control.value > between || control.value < between * -1) {
            return { outOfRange: true };
        }

        // it passes
        return null;
    };
}
