<div class="mat-elevation-z8" style="position: relative">
  <ngx-loading [show]="dataSource.loading$ | async"></ngx-loading>
  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Trailers">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <!-- License Column -->
    <ng-container matColumnDef="license">
      <th mat-header-cell *matHeaderCellDef>License #</th>
      <td mat-cell *matCellDef="let row">{{row.license_plate}}</td>
    </ng-container>

    <!-- Type Column -->
<!--    <ng-container matColumnDef="type">-->
<!--      <th mat-header-cell *matHeaderCellDef>Type</th>-->
<!--      <td mat-cell *matCellDef="let row">{{row.type.name}}</td>-->
<!--    </ng-container>-->

    <!-- Certified Column -->
    <ng-container matColumnDef="certified">
      <th mat-header-cell *matHeaderCellDef>Certified?</th>
      <td mat-cell *matCellDef="let row">
        <span style="vertical-align: middle">
          {{ row.certified_type_count > 0 ? 'Yes' : 'No' }}
        </span>
        <mat-icon style="vertical-align: middle" color="warn" *ngIf="row.certified_type_count === 0">warning</mat-icon>
      </td>
    </ng-container>

    <!-- Compatible Materials Column -->
    <ng-container matColumnDef="compatible_materials">
      <th mat-header-cell *matHeaderCellDef>Materials</th>
      <td mat-cell *matCellDef="let row">
        <span style="vertical-align: middle">
          {{row.compatible_materials}}
        </span>
        <mat-icon style="vertical-align: middle" color="warn" *ngIf=" ! row.is_compatible">warning</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="row.id != bol.trailer.id" type="button" (click)="onChange(row)" class="select-button" color="primary" mat-stroked-button
                        [disabled]="! row.is_compatible || row.certified_type_count === 0 ">
          select
        </button>
        <mat-icon *ngIf="row.id == bol.trailer.id" class="current-trailer">done</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
                 [length]="dataSource.pageCount"
                 [pageIndex]="0"
                 [pageSize]="25"
                 [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
