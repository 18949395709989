import { ApiService } from './api.service';
import { of } from 'rxjs';
import { OfflineActionsService } from './offline-actions.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./offline-actions.service";
var LoadService = /** @class */ (function () {
    function LoadService(apiService, offlineActionService) {
        this.apiService = apiService;
        this.offlineActionService = offlineActionService;
        this.uri = 'loads';
        // constructor(private http: HttpClient, private apiService: ApiService, private offlineActionService: OfflineActionsService) {
    }
    LoadService.prototype.create = function (loadOptions) {
        return this.apiService.post(this.uri, loadOptions);
    };
    LoadService.prototype.update = function (loadOptions, loadId) {
        return this.apiService.put(this.uri + "/" + loadId, loadOptions);
    };
    LoadService.prototype.finish = function (load, bolFinished) {
        if (bolFinished === void 0) { bolFinished = false; }
        var payload = {
            status: 'finished',
            bol_finished: bolFinished,
            billable_quantity: load.billable_quantity,
            estimated_loads_remaining: load.estimated_loads_remaining
        };
        return this.apiService.put(this.uri + "/" + load.id, payload);
    };
    LoadService.prototype.createLoadImage = function (loadId, image) {
        var uri = "load-images";
        var payload = {
            load_id: loadId,
            url: image.url,
            name: image.name,
            image_data: image.source
        };
        return this.apiService.post(uri, payload);
    };
    LoadService.prototype.deleteLoadImage = function (imageId) {
        var uri = "load-images/" + imageId;
        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'delete_load_image',
                data: {
                    id: imageId
                },
                method: 'delete',
                uri: uri,
                payload: {},
                performed_at: new Date().toUTCString()
            });
            return of({
                data: {}
            });
        }
        return this.apiService.delete(uri);
    };
    LoadService.ngInjectableDef = i0.defineInjectable({ factory: function LoadService_Factory() { return new LoadService(i0.inject(i1.ApiService), i0.inject(i2.OfflineActionsService)); }, token: LoadService, providedIn: "root" });
    return LoadService;
}());
export { LoadService };
