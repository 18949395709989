import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { map, shareReplay } from "rxjs/operators";

export interface Truck {
    name: string;
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class TruckService {

    public cachedGetAll$ = null;

    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 25) {

        return this.apiService.get('bol-trucks', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('trucks/' + id).pipe(
            map(res => res["data"])
        );
    }
}
