<div class="mat-elevation-z8" style="position: relative">
  <ngx-loading [show]="dataSource.loading$ | async"></ngx-loading>

  <div style="padding-top: 10px;">
    <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
      <mat-form-field [floatLabel]="'auto'" >
        <input matInput placeholder="Search" [formControlName]="'search'">
      </mat-form-field>
    </form>
  </div>


  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Trucks">
    <!-- Name Column -->
    <ng-container matColumnDef="truck_number">
      <th mat-header-cell *matHeaderCellDef>Truck Number</th>
      <td mat-cell *matCellDef="let row">{{row.truck_number}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="license">
      <th mat-header-cell *matHeaderCellDef>License #</th>
      <td mat-cell *matCellDef="let row">{{row.license_plate}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="bol_count">
      <th mat-header-cell *matHeaderCellDef>Active BOLs</th>
      <td mat-cell *matCellDef="let row">{{row.bols.length}}</td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="row.id != selectedTruck.id" type="button" (click)="onSave(row)" class="select-button" color="primary" mat-stroked-button>
          select
        </button>
        <mat-icon class="current-truck" *ngIf="row.id == selectedTruck.id">done</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
                 [length]="dataSource.pageCount"
                 [pageIndex]="0"
                 [pageSize]="25"
                 [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
