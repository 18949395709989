import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var RigService = /** @class */ (function () {
    function RigService(apiService) {
        this.apiService = apiService;
    }
    RigService.prototype.find = function (sortOrder, pageNumber, limit, search) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 10; }
        if (search === void 0) { search = null; }
        var params = {
            sortOrder: sortOrder,
            pageNumber: pageNumber,
            limit: limit
        };
        if (search) {
            params['search'] = search;
        }
        return this.apiService.get('rigs', { params: params });
    };
    RigService.prototype.findByCustomer = function (customerId) {
        return this.apiService.get('rigs', {
            params: {
                customer: customerId
            }
        });
    };
    RigService.prototype.findOne = function (id) {
        return this.apiService.get('rigs/' + id).pipe(map(function (res) { return res["data"]; }));
    };
    RigService.prototype.saveOrUpdate = function (payload, id) {
        if (id === void 0) { id = null; }
        if (id == null) {
            return this.apiService.post('rigs', payload);
        }
        return this.apiService.put('rigs/' + id, payload);
    };
    RigService.prototype.destroy = function (id) {
        return this.apiService.delete('rigs/' + id);
    };
    RigService.ngInjectableDef = i0.defineInjectable({ factory: function RigService_Factory() { return new RigService(i0.inject(i1.ApiService)); }, token: RigService, providedIn: "root" });
    return RigService;
}());
export { RigService };
