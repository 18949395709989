import Echo from "laravel-echo";
import * as Pusher from 'pusher-js';
import { NotificationService } from "./notification.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { ApiService } from "./api.service";
import { environment } from "../../environments/environment";
import { take } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./notification.service";
import * as i2 from "./api.service";
import * as i3 from "../authentication/authentication.service";
var SocketService = /** @class */ (function () {
    function SocketService(notificationService, apiService, authService) {
        this.notificationService = notificationService;
        this.apiService = apiService;
        this.authService = authService;
        this.retryTimer = null;
    }
    SocketService.prototype.initializeConnection = function () {
        var _this = this;
        var wsHost = environment.websocket_host;
        var wsPort = environment.websocket_port;
        var authEndpoint = environment.api_url + "/broadcasting/auth";
        var pusherKey = environment.pusher_key;
        var config = {
            wsHost: wsHost,
            authEndpoint: authEndpoint,
            wsPort: wsPort,
            disableStats: true,
            enabledTransports: ['ws'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.authService.token
                },
            },
        };
        var client = new Pusher(pusherKey, config);
        client.connection.bind('state_change', (function (states) {
            _this.processStateChange(states.previous, states.current);
        }));
        this.echo = new Echo({
            broadcaster: 'pusher',
            disableStats: true,
            authEndpoint: authEndpoint,
            namespace: 'GStyle',
            client: client
        });
        this.apiService.get('me').subscribe(function (response) {
            _this.user = response;
            _this.subscribeToNotifications();
        });
    };
    SocketService.prototype.processStateChange = function (previous, current) {
        if (previous === 'unavailable' && current === 'connected') {
            this.notificationService.fetchUnread().pipe(take(1)).subscribe();
        }
    };
    SocketService.prototype.subscribeToNotifications = function () {
        var _this = this;
        this.echo.private("users." + this.user.id)
            .notification(function (notification) {
            _this.notificationService.addUnreadNotification(notification, false, true);
        });
    };
    SocketService.prototype.setupRetry = function () {
        var _this = this;
        this.retryTimer = setInterval(function () {
            console.log('Retry connection');
            _this.echo.connect();
        }, 120 * 1000);
    };
    SocketService.ngInjectableDef = i0.defineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.inject(i1.NotificationService), i0.inject(i2.ApiService), i0.inject(i3.AuthenticationService)); }, token: SocketService, providedIn: "root" });
    return SocketService;
}());
export { SocketService };
