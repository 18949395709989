import { Component, OnInit } from '@angular/core';
import { BolService } from '../../common/bol.service';

@Component({
    selector: 'app-past-bill-of-lading-list',
    templateUrl: './past-bill-of-lading-list.component.html',
    styleUrls: ['./past-bill-of-lading-list.component.scss']
})
export class PastBillOfLadingListComponent implements OnInit {

    public bols = [];

    public length = 0;
    public pageSize = 25;
    public pageSizeOptions = [25];

    constructor(private bolService: BolService) {
    }

    ngOnInit() {
        this.fetchBols();
    }

    fetchBols(page = 1) {
        this.bolService.getArchivedBols(page).subscribe((response: any) => {
            this.bols = response.data;
            this.length = response.meta.total;
        });
    }

    pageEvent(event) {
        this.fetchBols(event.pageIndex + 1);
    }

    viewBol(bol) {
    }

}
