import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { TrailerTableDataSource } from './trailer-table-datasource';
import { TrailerService } from "../../../common/trailer.service";
import { tap } from "rxjs/operators";

@Component({
    selector: 'app-trailer-table',
    templateUrl: './trailer-table.component.html',
    styleUrls: ['./trailer-table.component.scss']
})
export class TrailerTableComponent implements OnInit, AfterViewInit {

    @Input() bol;
    @Output() save = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: TrailerTableDataSource;
    pageCount = 0;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['name', 'license', 'certified', 'compatible_materials', 'actions'];

    constructor(private trailerService: TrailerService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.dataSource = new TrailerTableDataSource(this.trailerService, this.bol);
        this.dataSource.load(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.load(this.paginator.pageIndex + 1, this.paginator.pageSize);
    }

    onChange(trailer) {
        this.save.emit(trailer);
    }

}
