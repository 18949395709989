<div class="load-image-container">
    <div class="load-image-header">
        <h3 class="tickets-title">Tickets/Manifests</h3>
        <ng-container *ngIf="loading">
                <mat-spinner diameter="30"></mat-spinner>
        </ng-container>
        <p *ngIf="images.length === 0">
            No pictures yet.
        </p>
    </div>

    <div *ngIf="!isBolComplete" class="image-input-wrapper">
        <label class="label" for="pictures">Add Tickets/Manifests</label> <br />
        <button mat-icon-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <input #fileInput class="choose-image" id="pictures" type="file" accept="image/*" multiple (change)="addPicture($event)">
        </button>
        <br /> <br />
    </div>
                
    <ng-container *ngIf="viewImageFail && !online">
        <p>You can only view saved images when online...</p>
    </ng-container>
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let image of images; let i = index;" class="load-image">
            <p matLine>{{ image.name}}</p>
            <button mat-icon-button (click)="view(image)">
                <mat-icon>pageview</mat-icon>
            </button>
            <button mat-icon-button (click)="destroy(image)" *ngIf="!isBolComplete">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>

</div>
