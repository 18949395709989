<h1 mat-dialog-title>Vehicles</h1>
<div mat-dialog-content>

    <mat-tab-group class="vehicle-tab">
        <mat-tab label="Truck">
            <ng-container *ngIf=" ! showTruckChange">
                <button *ngIf="data.archived !== true" color="primary" type="button" mat-flat-button (click)="changeTruck()">Change Truck</button>
                <mat-list class="vehicle-spec-list">
                    <mat-list-item>
                        <h3 matLine>{{ truck?.truck_number }}</h3>
                        <p matLine>
                            Name
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.make">
                        <h3 matLine>{{ truck.make}}</h3>
                        <p matLine>
                            Make
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.model">
                        <h3 matLine>{{ truck.model}}</h3>
                        <p matLine>
                            Model
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.year">
                        <h3 matLine>{{ truck.year}}</h3>
                        <p matLine>
                            Year
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.license">
                        <h3 matLine>{{ truck.license}}</h3>
                        <p matLine>
                            License #
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.vin">
                        <h3 matLine>{{ truck.vin}}</h3>
                        <p matLine>
                            VIN
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.tire_size">
                        <h3 matLine>{{ truck.tire_size}}</h3>
                        <p matLine>
                            Tire Size
                        </p>
                    </mat-list-item>
                    <mat-list-item *ngIf="truck.notes">
                        <h3 matLine>{{ truck.notes}}</h3>
                        <p matLine>
                            Notes
                        </p>
                    </mat-list-item>
                </mat-list>
            </ng-container>
            <app-truck-table *ngIf="showTruckChange" (save)="onTruckChange($event)" [selectedTruck]="truck"></app-truck-table>

        </mat-tab>
        <mat-tab label="Trailer">
            <ng-container *ngIf=" ! showTrailerChange">

            <button *ngIf="data.archived !== true" color="primary" type="button" mat-flat-button (click)="changeTrailer()">Change Trailer</button>
            <mat-list class="vehicle-spec-list">
                <mat-list-item>
                    <h3 matLine>{{ trailer?.name }}</h3>
                    <p matLine>
                        Name
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.make">
                    <h3 matLine>{{ trailer.make}}</h3>
                    <p matLine>
                        Make
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.model">
                    <h3 matLine>{{ trailer.model}}</h3>
                    <p matLine>
                        Model
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.year">
                    <h3 matLine>{{ trailer.year}}</h3>
                    <p matLine>
                        Year
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.license">
                    <h3 matLine>{{ trailer.license}}</h3>
                    <p matLine>
                        License #
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.vin">
                    <h3 matLine>{{ trailer.vin}}</h3>
                    <p matLine>
                        VIN
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.tire_size">
                    <h3 matLine>{{ trailer.tire_size}}</h3>
                    <p matLine>
                        Tire Size
                    </p>
                </mat-list-item>
                <mat-list-item *ngIf="trailer.notes">
                    <h3 matLine>{{ trailer.notes}}</h3>
                    <p matLine>
                        Notes
                    </p>
                </mat-list-item>
            </mat-list>
            </ng-container>
            <app-trailer-table *ngIf="showTrailerChange" [bol]="bol" (save)="onTrailerChange($event)"></app-trailer-table>
        </mat-tab>
    </mat-tab-group>



</div>
<div mat-dialog-actions class="dialog-actions" fxLayoutAlign="end end">
    <button mat-flat-button (click)="onClose()" color="primary">Close</button>
</div>
