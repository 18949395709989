import { Component, OnInit, NgZone, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BolService} from '../../common/bol.service';
import {WellService} from '../../common/well.service';
import {RigService} from '../../common/rig.service';
import {ApiService} from '../../common/api.service';
import {TruckService} from '../../common/truck.service';
import { mergeMap, flatMap, finalize, first, map } from 'rxjs/operators';
import { CustomerService } from 'src/app/common/customer.service';
import { UserService } from 'src/app/common/user.service';
import { BillingTypeService } from 'src/app/common/billing-type.service';
import { CompanyService } from '../../common/company.service';
import { forkJoin, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-bol',
  templateUrl: './view-bol.component.html',
  styleUrls: ['./view-bol.component.scss']
})
export class ViewBolComponent implements OnInit, AfterViewInit {

  public bol;
  public job;
  public well = null;
  public customer;
  public billedCustomer;
  public rig;
  public vehicle;
  public loading = true;
  public billType;
  public billTypeOptional;
  public totalQuantity;
  public totalQuantityOptional;
  public totalPrice;
  public totalPriceOptional;
  public images;
  private imageObservables = [];
  public dispatchers;
  private noLoads = false;
  public driver;
  public brand;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private bolService: BolService,
    private wellService: WellService,
    private rigService: RigService,
    private apiService: ApiService,
    private customerService: CustomerService,
    private truckService: TruckService,
    private billingTypeService: BillingTypeService,
    private ngZone: NgZone,
    private userService: UserService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    const bolId = this.route.snapshot.params.id;

    this.bolService.get(bolId).subscribe((response) => {
      this.job = response.data.job;
      this.bol = response.data;
      this.gatherData();
    });
  }

  gatherData() {
    this.gatherImages();
    this.findPricing();

    // keeping track of what values are being requested so we can make the requests at the same time
    const observables = [];
    const usedValues = [];

    // well and rig
    let wellObserve;
    if (this.job.well_id !== null) {
      wellObserve = this.wellService.findOne(this.job.well_id).pipe(
        mergeMap((well) => {
          this.well = well;
          if (well.rig_id) {
            return this.rigService.findOne(well.rig_id);
          } else {
            // if there is no rig attached to the well
            return new Observable(subscriber => {
              subscriber.next(null as never);
              subscriber.complete();
            });
          }
        }));
      observables.push(wellObserve);
      usedValues.push('rig');
    }
    // customer
    let customerObserve;
    if (this.job.customer_id !== null) {
      customerObserve = this.customerService.findOne(this.job.customer_id);
      observables.push(customerObserve);
      usedValues.push('customer');
    }
    // billed customer
    let billedCustomerObserve;
    if (this.job.billed_company_id !== null) {
      billedCustomerObserve = this.customerService.findOne(this.job.billed_company_id);
      observables.push(billedCustomerObserve);
      usedValues.push('billedCustomer');
    }
    // driver
    let driverObserve;
    if (this.job.customer_id !== null) {
      driverObserve = this.userService.getMe();
      observables.push(driverObserve);
      usedValues.push('driver');
    }
    // truck
    let truckObserve;
    if (this.bol.truck_id !== null) {
      truckObserve = this.truckService.findOne(this.bol.truck_id);
      observables.push(truckObserve);
      usedValues.push('vehicle');
    }
    // billing type
    let billableTypeObserve;
    if (this.job.billing_type_id) {
      billableTypeObserve = this.billingTypeService.findOne(this.job.billing_type_id);
      observables.push(billableTypeObserve);
      usedValues.push('billType');
    }
    // optional billing type
    let billableTypeOptionalObserve;
    if (this.job.billing_type_optional_id) {
      billableTypeOptionalObserve = this.billingTypeService.findOne(this.job.billing_type_optional_id);
      observables.push(billableTypeOptionalObserve);
      usedValues.push('billTypeOptional');
    }

    // get company information
    let companyObserve;
    if (!this.job.company) {
      companyObserve = this.companyService.findOne(this.job.company_id).pipe(
        mergeMap(response => {
          if (response.logo) {
            // get the image
            return this.companyService.findOneLogo(response.logo).pipe(
              map((logoResponse) => {
                return ({...response, logo: logoResponse});
              })
            );
          }
          // if no logo just instantly send response
          // there's probably a better way to do this
          return new Observable(subscriber => {
            subscriber.next(response as never);
            subscriber.complete();
          });
        })
      );

      observables.push(companyObserve);
      usedValues.push('brand');
    }

    const gatherPictures = forkJoin(this.imageObservables);
    if (this.noLoads === false && this.imageObservables.length !== 0) {
      gatherPictures.subscribe((images) => {
        this.images = images;
      });
    }

    const gatherData = forkJoin(observables);

    gatherData.pipe(
      finalize(() => this.loading = false),
      mergeMap((responses) => {
        // include every used value that was subscribed to
       for (let i = 0; i < usedValues.length; i++) {
        if (usedValues[i] === 'rig') {
          this.rig = responses[i];
        }
        if (usedValues[i] === 'customer') {
          this.customer = responses[i];
        }
        if (usedValues[i] === 'billedCustomer') {
          this.billedCustomer = responses[i];
        }
        if (usedValues[i] === 'vehicle') {
          this.vehicle = responses[i];
        }
        if (usedValues[i] === 'billType') {
          this.billType = responses[i];
        }
        if (usedValues[i] === 'billTypeOptional') {
          this.billTypeOptional = responses[i];
        }
        if (usedValues[i] === 'dispatchers') {
          this.dispatchers = responses[i];
        }
        if (usedValues[i] === 'driver') {
          this.driver = responses[i];
        }
        if (usedValues[i] === 'brand') {
          this.brand = responses[i];
        }
       }
       return this.ngZone.onMicrotaskEmpty.pipe(first());
      })).subscribe(() => {
    });
  }
  findPricing() {
    let totalQuantity = 0;
    let quantityOptional = 0;
    for (let i = 0; i < this.bol.loads.length; i++) {
      totalQuantity += Number(this.bol.loads[i].billable_quantity);
      quantityOptional += Number(this.bol.loads[i].billable_quantity_optional);
    }
    this.totalQuantity = totalQuantity;
    this.totalQuantityOptional = quantityOptional;

    this.totalPrice = this.job.price_per_unit * this.totalQuantity;
    this.totalPrice = this.totalPrice.toFixed(2);

    this.totalPriceOptional = this.job.price_per_unit * this.totalQuantityOptional;
    this.totalPriceOptional = this.totalPriceOptional.toFixed(2);
  }

  gatherImages() {
    const loads = this.bol.loads;
    if (loads.length !== 0) {
      for (let i = 0; i < loads.length; i++) {
        const images = loads[i].images;
        if (images.length !== 0) {
          for (let image = 0; image < images.length; image++) {
            const imageId = images[image].id;
            this.imageObservables.push(
              this.apiService.get(`load-images/${imageId}`, {
                responseType: 'blob',
                headers: {
                  accept: 'image/*'
                }
              }).pipe(flatMap(res => {
                const imageBlob = res as any as Blob;
                return this.readImageData(imageBlob);
              } ))
            );
          }
        }
      }
    } else {
      return this.noLoads = true;
    }
  }
  readImageData(blob: Blob): Observable<any> {
    return new Observable(observer => {
      let reader = new FileReader();
      reader.onloadend = function() {
          observer.next(reader.result);
          observer.complete();
      };
      reader.readAsDataURL(blob);
    });
  }

  back() {
    this.location.back();
  }

}
