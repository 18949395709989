import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {SwPush} from "@angular/service-worker";
import {SocketService} from "../common/socket.service";
import {ApiService} from "../common/api.service";
import {environment} from "../../environments/environment";
import {OfflineActionsService} from "../common/offline-actions.service";
import { SessionService } from "../common/session.service";
import {NotificationService} from "../common/notification.service";
import {PwaService} from "../common/pwa.service";
import { VisibilityService } from "../common/visibility.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
    styleUrls: ['./driver.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DriverComponent implements OnInit {

    readonly VAPID_PUBLIC_KEY = environment.vapid_key;
    public user;

    public steps = {
        notifications_requested: false,
        updated_user_subscription: false
    };

    constructor(private injector: Injector,
                private socketService: SocketService,
                private offlineService: OfflineActionsService,
                private sessionService: SessionService,
                private visibilityService: VisibilityService,
                private notificationService: NotificationService,
                public pwaService: PwaService,
                public swPush: SwPush,
                public router: Router,
                public route: ActivatedRoute,
                private apiService: ApiService) {}

    ngOnInit() {

        this.user = this.sessionService.user;

        if (environment.use_service_workers) {
            this.subscribeToPush();
        }

        this.notificationService.fetchUnread().subscribe();

        // reload notifications if visibility has been blurred and comes back
        this.visibilityService.visibilityChange.subscribe(hidden => {
            if( ! hidden) {
                this.notificationService.fetchUnread().subscribe();
            }
        });

        this.offlineService.init();
        this.socketService.initializeConnection();
    }

    installPwa(): void {
        this.pwaService.promptEvent.prompt();
    }

    subscribeToPush() {
        if (!environment.use_service_workers) {
            return;
        }

        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        }).then(subscription => {
            this.steps.notifications_requested = true;

            const key = subscription.getKey('p256dh');
            const token = subscription.getKey('auth');

            const data = {
                endpoint: subscription.endpoint,
                key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null
            };


            // this.swPush.notificationClicks.subscribe((notification: any) => {
            //
            //
            // });

            this.apiService.put('me/push-subscriptions', data).subscribe(response => {
                this.steps.updated_user_subscription = true;
            });
        }).catch(err => console.error("Could not subscribe to notifications", err));
    }
}
