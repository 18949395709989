<mat-list-item class="notification-card mat-elevation-z1" [ngClass]="{ 'unread': ! notification.read  }">
    <mat-icon *ngIf="notification.data.icon" matListIcon>{{ notification.data.icon }}</mat-icon>

    <img matListAvatar alt="User avatar" *ngIf="notification.data.avatar && notification.data.avatar === null" class="user-avatar" src="/assets/images/default-avatar.png" />
    <img matListAvatar alt="User avatar" *ngIf="notification.data.avatar && notification.data.avatar !== null" class="user-avatar" [src]="notification.data.avatar" />

    <h3 matLine>{{ notification.data.title }}</h3>
    <p matLine>{{ notification.created_at }}</p>
    <p matLine>
        <span>{{ notification.data.message}}</span>
    </p>
    <button type="button" mat-icon-button (click)="toggleRead($event, notification)" [disabled]="notification.read">
        <mat-icon style="font-size: 0.75rem" >brightness_1</mat-icon>
    </button>
</mat-list-item>
