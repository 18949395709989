import { ApiService } from "./api.service";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var BolCommentService = /** @class */ (function () {
    function BolCommentService(apiService) {
        this.apiService = apiService;
    }
    BolCommentService.ngInjectableDef = i0.defineInjectable({ factory: function BolCommentService_Factory() { return new BolCommentService(i0.inject(i1.ApiService)); }, token: BolCommentService, providedIn: "root" });
    return BolCommentService;
}());
export { BolCommentService };
