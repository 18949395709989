import { Component, OnInit } from '@angular/core';
import {NotificationService} from "../../common/notification.service";
import {map, finalize} from "rxjs/operators";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    public notifications = [];

    public loading  = false;
    public length = 0;
    public pageSize = 15;
    public pageSizeOptions = [15];

    constructor(private notificationService: NotificationService) {

    }

    ngOnInit() {
        this.loading = true;
        this.fetchNotifications();

        this.notificationService.unreadNotifications$.subscribe(response => {
            this.fetchNotifications();
        });

        this.notificationService.onMarkAsRead().subscribe((event: any) => {
            if(event.from !== 'notification-list') {
                this.fetchNotifications();
            }
        });

        this.notificationService.onMarkAllAsRead().subscribe((event: any) => {
            if(event.from !== 'notification-list') {
                this.fetchNotifications();
            }
        });
    }

    fetchNotifications(page = 1) {
        this.notificationService.fetchAll(page).pipe(map((response: any) => {
            response.data = response.data.map((notification: any) => {
                notification.read = notification.read_at !== null;
                return notification;
            });

            return response;
        })).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.notifications = response.data;
            this.length = response.total;
        });
    }

    deleteAllRead() {
        this.loading = true;
        this.notificationService.deleteAllRead().subscribe(response => {
            this.fetchNotifications();
        });
    }

    markAllAsRead() {
        this.loading = true;
        this.notificationService.markAllAsRead('notification-list').subscribe(response => {
            this.fetchNotifications();
        });
    }

    pageEvent(event) {
        this.fetchNotifications(event.pageIndex + 1);
    }

    onRead(event) {
        const index = this.notifications.indexOf(event);

        this.notificationService.markAsRead(event, 'notification-list').subscribe(response => {
            this.notifications[index].read = true;
        });
    }

}
