import {Injectable} from '@angular/core';
import { ApiService } from "./api.service";
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";
import {map, share, shareReplay, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public unreadNotifications$ = new BehaviorSubject([]);

    public markAllAsReadSubject = new Subject();
    public markAsReadSubject = new Subject();

    public unreadNotifications = [];

    public onMarkAllAsRead() {
        return this.markAllAsReadSubject;
    }

    public onMarkAsRead() {
        return this.markAsReadSubject;
    }

    constructor(private apiService: ApiService) {
    }

    public addUnreadNotification(notification, read = false, broadcast = false) {
        notification.read = read;
        this.unreadNotifications.push(notification);

        if(broadcast === true) {
            this.unreadNotifications$.next(this.unreadNotifications);
        }
    }

    public fetchUnread() {
        this.unreadNotifications = [];
        return this.apiService.get('me/notifications?type=unread').pipe(tap((response: any) => {
            for(let notification of response) {
                this.addUnreadNotification(notification, notification.read_at !== null);
            }
            this.unreadNotifications$.next(this.unreadNotifications);
        }));
    }

    public fetchAll(page = 1) {
        return this.apiService.get(`me/notifications?page=${page}`);
    }

    public markAllAsRead(from) {
        return this.apiService.put('me/notifications').pipe(tap( () => {
            this.markAllAsReadSubject.next({
                from: from
            });

            this.unreadNotifications = [];
            this.unreadNotifications$.next(this.unreadNotifications);
        }));
    }

    public deleteAllRead() {
        return this.apiService.delete('me/notifications');
    }

    public markAsRead(notification, from) {
        return this.apiService.put(`me/notifications/${notification.id}`, {
            read: true
        }).pipe(tap( () => {
            this.markAsReadSubject.next({
                from: from,
                id: notification.id
            });

            const index = this.unreadNotifications.findIndex(element => element.id === notification.id);

            if(index != -1) {
                this.unreadNotifications.splice(index, 1);
                this.unreadNotifications$.next(this.unreadNotifications);
            }
        }));
    }
}
