import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DriverComponent} from './driver.component';
import {BillOfLadingListComponent} from "./bill-of-lading-list/bill-of-lading-list.component";
import {BillOfLadingDetailComponent} from "./bill-of-lading-detail/bill-of-lading-detail.component";
import {LoadComponent} from "./bill-of-lading-detail/load/load.component";
import {ListBillOfLadingComponent} from "./bill-of-lading-list/bill-of-lading/bill-of-lading.component";
import {NotificationCardComponent} from "./notification-card/notification-card.component";
import {ConnectionStatusComponent} from "./connection-status/connection-status.component";
import { driverRoutes } from "./driver.routing";
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule, MatProgressSpinnerModule, MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule, MatTableModule, MatSortModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule
} from "@angular/material";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {AgmCoreModule} from "@agm/core";
import {AgmDirectionModule} from "agm-direction";
import {RouterModule} from "@angular/router";
import {TokenInterceptor} from "../authentication/token.interceptor";
import {BillOfLadingComponent} from "./bill-of-lading-detail/bill-of-lading/bill-of-lading.component";
import {NgxLoadingModule} from "ngx-loading";
import { CommonModule as GstyleCommon } from "../common/common.module";
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import {FileInputAccessorModule} from "file-input-accessor";
import { CommentsComponent } from './bill-of-lading-detail/comments/comments.component';
import { LoadImagesComponent } from './bill-of-lading-detail/load-images/load-images.component';
import { CrystalGalleryModule } from "ngx-crystal-gallery";
import { ProfileComponent } from './profile/profile.component';
import { ImageCropperModule } from "ngx-image-cropper";
import { NotificationsComponent } from './notifications/notifications.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { CommentModalComponent } from './bill-of-lading-detail/comment-modal/comment-modal.component';
import {environment} from "../../environments/environment";
import { VehicleModalComponent } from './vehicle-modal/vehicle-modal.component';
import { LocationsModalComponent } from './locations-modal/locations-modal.component';
import { LoadRouteComponent } from './bill-of-lading-detail/load-route/load-route.component';
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { ChangeVehicleComponent } from './change-vehicle/change-vehicle.component';
import { TruckTableComponent } from './change-vehicle/truck-table/truck-table.component';
import { ChangeTrailerComponent } from './change-trailer/change-trailer.component';
import { TrailerTableComponent } from './change-trailer/trailer-table/trailer-table.component';
import { PastBillOfLadingListComponent } from './past-bill-of-lading-list/past-bill-of-lading-list.component';
import { PastBillOfLadingDetailComponent } from './past-bill-of-lading-detail/past-bill-of-lading-detail.component';
import { JobSafetyAnalysisComponent } from './job-safety-analysis/job-safety-analysis.component';
import { ViewBolComponent } from './view-bol/view-bol.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



@NgModule({
    declarations: [
        DriverComponent,
        BillOfLadingListComponent,
        BillOfLadingComponent,
        BillOfLadingDetailComponent,
        ListBillOfLadingComponent,
        NotificationCardComponent,
        ConnectionStatusComponent,
        LoadComponent,
        NotificationListComponent,
        NotificationDropdownComponent,
        CommentsComponent,
        LoadImagesComponent,
        ProfileComponent,
        NotificationsComponent,
        ChangePasswordComponent,
        CommentModalComponent,
        VehicleModalComponent,
        LocationsModalComponent,
        LoadRouteComponent,
        ChangeVehicleComponent,
        TruckTableComponent,
        ChangeTrailerComponent,
        TrailerTableComponent,
        PastBillOfLadingListComponent,
        PastBillOfLadingDetailComponent,
        JobSafetyAnalysisComponent,
        ViewBolComponent
    ],
    imports: [
        GstyleCommon,
        RouterModule.forChild(driverRoutes),
        CommonModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatIconModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        HttpClientModule,
        FlexLayoutModule,
        MatDialogModule,
        ImageCropperModule,
        ReactiveFormsModule,
        LoadingBarHttpClientModule,
        NgxLoadingModule,
        CrystalGalleryModule,
        MatPaginatorModule,
        AgmCoreModule.forRoot({
            apiKey: environment.google_maps_key,
            libraries: ['places']
        }),
        AgmDirectionModule,
        MatGoogleMapsAutocompleteModule.forRoot(),
        FileInputAccessorModule,
        MatTableModule,
        MatSortModule,
        SignaturePadModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
    ],
    entryComponents: [
        CommentModalComponent,
        LoadRouteComponent,
        VehicleModalComponent,
        LocationsModalComponent
    ]
})
export class DriverModule {
}
