import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: 'app-comment-modal',
    templateUrl: './comment-modal.component.html',
    styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements AfterViewChecked {

    @ViewChild('container') private container: ElementRef;

    constructor(public dialogRef: MatDialogRef<CommentModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

    onClose(): void {
        this.dialogRef.close({});
    }
}
