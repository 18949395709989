<div class="change-password-container" style="position: relative">
  <ngx-loading [show]="loading"></ngx-loading>
  <form [formGroup]="form" (ngSubmit)="submit()">

    <div class="alert alert-danger" *ngIf="form.get('new_password_confirmation').hasError('matchPassword')">Passwords do not match</div>

    <mat-form-field [floatLabel]="'auto'" style="width: 100%; margin-bottom: 15px;">
      <input type="password" name="current_password" matInput placeholder="Current Password" required [formControlName]="'current_password'">
      <mat-error *ngIf="form.get('current_password').hasError('required') && submitted">
        Current password is required
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="'auto'" style="width: 100%; margin-bottom: 15px;">
      <input type="password" name="new_password" matInput placeholder="New Password" required [formControlName]="'new_password'">
      <mat-error *ngIf="form.get('new_password').hasError('required') && submitted">
        New password is required
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="'auto'" style="width: 100%; margin-bottom: 15px;">
      <input type="password" name="new_password_confirmation" matInput placeholder="Confirm New Password" required [formControlName]="'new_password_confirmation'">
      <mat-error *ngIf="form.get('new_password_confirmation').hasError('required') && submitted">
        Password confirmation is required
      </mat-error>
    </mat-form-field>

    <button type="submit" mat-flat-button color="primary">Save</button>
  </form>
</div>
