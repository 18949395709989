import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";


export interface BillingType {
    name: string;
    id: number;
    rate: number;
}


@Injectable({
    providedIn: 'root'
})
export class BillingTypeService {

    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10) {
        return this.apiService.get('billing-types', {
            params: {
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('billing-types/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('billing-types', payload)
        }

        return this.apiService.put('billing-types/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('billing-types/' + id);
    }

}
