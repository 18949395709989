import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {authRoutes} from "./authentication.routings";
import {AuthenticationService} from "./authentication.service";
import {AuthenticationGuard} from "./authentication.guard";

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(authRoutes),
        CommonModule
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard
    ]
})
export class AuthenticationModule {
}
