import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";

export interface Location {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class LocationsService {

  constructor(protected apiService: ApiService) {
  }

  public find(sortOrder = 'asc', pageNumber = 1, limit = 25, search = null) {

    let params = {
      page: pageNumber.toString(),
      limit: limit.toString()
    }

    if(search){
      params['search'] = search;
    }

    return this.apiService.get('locations', {params});
  }

  public getByType(type, search): any {
    return this.apiService.get('locations', {
      params: {
        type: type,
        search: search,
        mode: 'autocomplete'
      }
    });
  }

  public findOne(id): any {
    return this.apiService.get('locations/' + id);
  }

  public saveOrUpdate(payload: { name: string }, id = null) {
    if (id == null) {
      return this.apiService.post('locations', payload)
    }

    return this.apiService.put('locations/' + id, payload);
  }

  public destroy(id) {
    return this.apiService.delete('locations/' + id);
  }

}
