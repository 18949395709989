import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Customer} from "./customer.service";

export interface Rig {
    id: number;
    name: string;
    customer: Customer
}

@Injectable({
    providedIn: 'root'
})
export class RigService {
    constructor(protected apiService: ApiService) {
    }

    public find(sortOrder = 'asc', pageNumber = 1, limit = 10, search = null) {
        let params = {
            sortOrder,
            pageNumber,
            limit
        }
        if (search) {
            params['search'] = search
        }
        return this.apiService.get('rigs', {params});
    }

    public findByCustomer(customerId) {
        return this.apiService.get('rigs', {
            params: {
                customer: customerId
            }
        });
    }

    public findOne(id) {
        return this.apiService.get('rigs/' + id).pipe(
            map(res => res["data"])
        );
    }

    public saveOrUpdate(payload: { name: string }, id = null) {
        if (id == null) {
            return this.apiService.post('rigs', payload)
        }

        return this.apiService.put('rigs/' + id, payload);
    }

    public destroy(id) {
        return this.apiService.delete('rigs/' + id);
    }
}
